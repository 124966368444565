<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="12">
            <h3>صفحة تسجيل الطالب</h3>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-form ref="studentRequiredForm" v-model="studentRequiredForm" class="pt-3 pb-3" style="padding: 20px" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field :value="calculateCollegeNumber()" outlined color="primary" label="الرقم الاحصائي" disabled></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field v-model="studentName" outlined color="primary" label="اسم الطالب" :rules="studentRequierdFormRules"></v-text-field>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field v-model="studentEnglishName" outlined color="primary" label="الاسم الانجليزي" :rules="studentRequierdFormRules"></v-text-field>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-menu ref="dobMenuRef" v-model="dobMenuModel" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template #activator="{ on, attrs }">
                  <v-text-field v-model="studentDob" outlined color="primary" label="تاريخ الميلاد" v-bind="attrs" :rules="studentRequierdFormRules" v-on="on"></v-text-field>
                </template>

                <v-date-picker
                  v-model="studentDob"
                  :active-picker.sync="dobActivePicker"
                  :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="saveDob"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field v-model="studentMail" outlined color="primary" label="البريد الالكتروني" :rules="studentRequierdFormRules"></v-text-field>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete v-model="studentNationality" outlined color="primary" label="الجنسية" :rules="studentRequierdFormRules" :items="listOfNational" item-color="primary"></v-autocomplete>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete
                v-model="studentGender"
                outlined
                color="primary"
                label="الجنس"
                :items="selectGender"
                item-text="text"
                item-value="value"
                item-color="primary"
                :rules="studentRequierdFormRules"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete
                v-model="studentSection"
                outlined
                color="primary"
                label="القسم"
                :items="selectSection"
                item-text="sectionName"
                item-value="idSection"
                item-color="primary"
                :rules="studentRequierdFormRules"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete
                v-model="studentStudyType"
                outlined
                color="primary"
                label="نوع الدراسة"
                :items="selectStudyType"
                item-text="text"
                item-value="value"
                item-color="primary"
                :rules="studentRequierdFormRules"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete
                v-model="studentLevel"
                outlined
                color="primary"
                label="المرحلة"
                :items="selectLevel"
                item-text="text"
                item-value="value"
                item-color="primary"
                :rules="studentRequierdFormRules"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete
                v-model="studentAcceptedType"
                outlined
                color="primary"
                label="نوع القبول"
                :items="selectAcceptedType"
                item-text="acceptedName"
                item-value="idAcceptedType"
                item-color="primary"
                :rules="studentRequierdFormRules"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete
                v-model="studentRegisterYear"
                outlined
                color="primary"
                label="سنة القبول"
                :items="selectRegisterYear"
                item-text="year"
                item-value="idYearStudy"
                item-color="primary"
                :rules="studentRequierdFormRules"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete
                v-model="studentStatus"
                outlined
                color="primary"
                label="حالة الطالب"
                :items="selectStatus"
                item-text="statusName"
                item-value="idStudentStatus"
                item-color="primary"
                :rules="studentRequierdFormRules"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-textarea v-model="studentNote" outlined color="primary" label="الملاحظات" :rules="studentRequierdFormRules"></v-textarea>
            </v-col>
          </v-row>

          <v-btn depressed color="primary" :disabled="!studentRequiredForm" large @click.stop="registerStudents">
            <span class="secondary--text">تسجيل الطالب</span>
          </v-btn>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "BasicStudentInfo",
  data() {
    return {
      dobActivePicker: null,
      dobMenuModel: false,
      studentRequierdFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      studentRequiredForm: false,
      studentName: "",
      studentEnglishName: "none",
      studentDob: moment(new Date()).format("YYYY-MM-DD"),
      studentGender: "",
      studentMail: "turath.student@turath.edu.iq",
      studentNationality: "عراقي",
      studentSection: "",
      studentStudyType: "",
      studentAcceptedType: "",
      studentLevel: "",
      studentRegisterYear: "",
      studentStatus: "",
      studentNote: "لا يوجد",
      listOfNational: ["عراقي", "مصري", "سوري", "اردني", "سعودي", "كويتي", "ليبي", "لبناني", "فلسطيني", "سوداني", "جزائري", "مغربي"],
      selectGender: [
        {
          text: "انثى",
          value: "2",
        },
        {
          text: "ذكر",
          value: "1",
        },
      ],
      selectStudyType: [
        {
          text: "صباحي",
          value: "1",
        },
        {
          text: "مسائي",
          value: "2",
        },
      ],
      selectLevel: [
        {
          text: "المرحلة الاولى",
          value: 1,
        },
        {
          text: "المرحلة الثانية",
          value: 2,
        },
        {
          text: "المرحلة الثالثة",
          value: 3,
        },
        {
          text: "المرحلة الرابعة",
          value: 4,
        },
        {
          text: "المرحلة الخامسة",
          value: 5,
        },
      ],
      selectSection: [],
      selectAcceptedType: [],
      selectRegisterYear: [],
      selectStatus: [],

      sectionCode: "",
      sectionCount: "",
      collegeNumber: null,
      currentCount: {
        studentsCount: 0,
      },

      sectionCode: 0,
      totalStudents: 0,
    };
  },

  watch: {
    dobMenuModel(val) {
      val && setTimeout(() => (this.dobActivePicker = "YEAR"));
    },
  },

  created() {
    this.initialData();
  },

  methods: {
    saveDob(date) {
      this.$refs.dobMenuRef.save(date);
    },
    initialData() {
      let self = this;
      self.overlay = true;

      self.totalStudents = Math.floor(Math.random() * 200);
      self.sectionCode = Math.floor(Math.random() * 30);

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlRegistration}/acceptedTypes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/sections`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/yearStudies`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/allStudentStatus`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((acceptedTypes, sections, yearStudies, studentStatus) => {
            console.log(sections);
            this.selectAcceptedType = acceptedTypes.data;
            this.selectSection = sections.data;
            this.selectRegisterYear = yearStudies.data;
            this.selectStatus = studentStatus.data;
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    calculateCollegeNumber() {
      let self = this;
      let dob = self.studentDob.slice(2, 4);
      let gender = self.gender == "ذكر" ? 0 : 1;
      return `23${self.studentRegisterYear}${self.sectionCode}${dob}${gender}${self.totalStudents}`;
    },
    registerStudents() {
      let self = this;
      self.overlay = true;

      if (
        self.studentName &&
        self.studentEnglishName &&
        self.studentMail &&
        self.studentDob &&
        self.studentGender &&
        self.studentStudyType &&
        self.studentRegisterYear &&
        self.studentSection &&
        self.studentNote &&
        self.studentLevel
      ) {
        const student = {
          studentName: this.studentName,
          englishName: this.studentEnglishName,
          mail: this.studentMail,
          dob: this.studentDob,
          sectionId: this.studentSection,
          nationality: this.studentNationality,
          gender: this.studentGender,
          studyType: this.studentStudyType,
          acceptedTypeId: this.studentAcceptedType * 1,
          registerYearId: this.studentRegisterYear,
          studentStatusId: this.studentStatus,
          note: this.studentNote,
          collegeNumber: this.calculateCollegeNumber(),
        };

        this.$http
          .post(
            `${this.$store.state.apiUrlRegistration}/addStudent`,

            student,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            let studentLevel = {
              level: this.studentLevel,
              class: "A",
              yearStudyId: this.studentRegisterYear,
              studentId: res.data.id,
              currentLevel: 1,
            };
            this.$http
              .post(
                `${this.$store.state.apiUrlRegistration}/addStudentLevel`,

                studentLevel,
                {
                  headers: { Authorization: `Bearer ${self.$store.state.user}` },
                }
              )
              .then((resLevel) => {
                this.$toasted.success("تم اضافه البيانات");
                self.studentName = "";
                self.studentEnglishName = "none";
                self.studentGender = "";
                self.studentStudyType = "";
                self.studentRegisterYear;
                self.studentSection = "";
                self.overlay = false;
              })
              .catch((err) => {
                this.$toasted.error(" حدث خطأ في البيانات");
                self.overlay = false;
              });
          })
          .catch((err) => {
            this.$toasted.error(" حدث خطأ في البيانات");
            self.overlay = false;
          });
      } else {
        self.overlay = false;
        this.$toasted.error("الرجاء ادخال ادخال جميع القيم");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
