<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>تبويب الايرادات</h3>
          </v-col>
          <v-col cols="2" align="left">
            <AddReceiptType style="display: inline-block" @reloadTask="initialData" v-if="!overlay && $store.state.userData.idEmployee != 614" />
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="sectionInstallments" :items-per-page="15" :search="search" item-key="idStudent" class="table-content">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            <p style="padding: 0 3px">{{ item.level | setLevel }}</p>
          </template>

          <template v-slot:[`item.studyType`]="{ item }">
            <p style="padding: 0 3px">{{ item.studyType | setStudyType }}</p>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <p style="padding: 0 3px">
              {{
                item.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </p>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="dialog = false" v-if="$store.state.userData.idEmployee != 614">
              <v-icon @click="deleteItem(item)" color="primary"> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddReceiptType from "../components/AddReceiptType.vue";
export default {
  components: {
    AddReceiptType,
  },
  data: () => ({
    search: "",
    overlay: false,
    selected: [],
    sectionInstallments: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "اسم التبويب", value: "typeName" },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.overlay = true;
      self.$http
        .get(`${self.$store.state.apiUrlFees}/receiptTypes`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.sectionInstallments = res.data;
          self.overlay = false;
        })
        .catch((e) => {
          console.log(e);
          self.overlay = false;
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
    selectStudent(student) {
      console.log(student);
    },
    deleteItem(item) {
      let self = this;
      self.$http
        .delete(`${self.$store.state.apiUrlFees}/receiptType/${item.idReceiptType}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          this.initialData();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>

<!-- 
43901
SELECT * FROM StudentLevel WHERE studentId = 43901
DELETE FROM StudentLevel WHERE studentId = 43901

UPDATE StudentPayment SET studentLevel = 4 WHERE idStudentFees = 205341
UPDATE StudentPayment SET studentLevel = 4 WHERE idStudentFees = 247266
UPDATE StudentPayment SET studentLevel = 4 WHERE idStudentFees = 247268
UPDATE StudentPayment SET studentLevel = 4 WHERE idStudentFees = 247331

SELECT * FROM Student WHERE collegeNumber = "23742223149"

SELECT * FROM StudentPayment WHERE collegeNumber = "21101526482"

UPDATE StudentPayment SET sectionId =  WHERE collegeNumber = "212100802769"
DELETE FROM Student WHERE collegeNumber = "23742223149" -->
