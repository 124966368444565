<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app class="pt-4 not-printable" color="primary" mini-variant fixed right v-if="$store.state.user != null">
      <!-- <v-avatar v-for="(item, i) in items" :key="i" class="d-block text-center mx-auto mb-9"> <v-icon v-text="item.icon" color="white"></v-icon></v-avatar>
      <v-avatar @click="logout">
        <v-icon left color="white"> mdi-account-circle </v-icon>
      </v-avatar> -->
      <!-- <span v-if="$store.state.user != null">{{ waitForLogin() }}</span> -->
      <v-list>
        <v-subheader></v-subheader>
        <v-list-item-group v-model="selectedItem" color="accent">
          <v-list-item v-for="(item, i) in $store.state.items" :key="i" style="padding: 10px 15px" @click="page(item)">
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="white">{{ item.icon }}</v-icon>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
          </v-list-item>

          <v-menu right :offset-x="offset" min-width="300" max-width="300" v-if="$store.state.userData.roleId != 61">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item style="padding: 10px 15px">
                <v-icon color="white" dark v-bind="attrs" v-on="on">mdi-backburger</v-icon>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in $store.state.subList" :key="index" @click="page(item)">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-icon left color="white" @click="modifiedLevel()" v-if="$store.state.userData.roleId != 61"> mdi-monitor-edit </v-icon>
          <div style="height: 10px"></div>
          <v-icon left color="white" @click="logoutPage()"> mdi-account-circle </v-icon>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="primary" v-if="$store.state.user != null" class="not-printable">
      <div class="d-flex align-center" style="width: 100%">
        <div style="text-align: right">
          <v-avatar color="secondary" size="50">
            <v-img :src="require('./assets/logo.png')" class="my-3" contain />
          </v-avatar>
        </div>
        <v-spacer></v-spacer>
        <h5 style="color: aliceblue">برنامج القسم المالي</h5>
        <v-spacer></v-spacer>
        <h5 @click="logoutPage()" style="color: aliceblue">{{ $store.state.userData.employeeName }}</h5>
      </div>
    </v-app-bar>

    <v-main class="center-print adjust-main">
      <div>
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    selectedItem: 0,
    drawer: true,

    offset: true,
    login: false,
  }),
  created() {
    this.$router.push("/login").catch(() => {});
  },
  methods: {
    logoutPage() {
      this.$router.push("/profile").catch(() => {});
    },
    modifiedLevel() {
      this.$router.push("/modified").catch(() => {});
    },
    page(item) {
      console.log(this.selectedItem);
      this.$router.push(item.title).catch(() => {});
    },
    logout() {
      localStorage.removeItem("employee");
      location.reload();
    },
  },
};
</script>
<style>
@import url("./font.css");
@media print {
  @page {
    size: A5 landscape !important;
    margin: 0cm;
  }
  @page :left {
    margin: 0cm;
  }

  @page :right {
    margin: 0cm;
  }
  .not-printable {
    display: none !important;
  }
  .not-printable {
    display: none !important;
  }
  body,
  html,
  main {
    -webkit-print-color-adjust: exact !important;
    height: 100% !important;
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
    color: #000;
    background-color: #fff !important;
  }
  .adjust-main {
    padding: 0px !important;
  }
  header nav,
  footer {
    display: none;
  }
  h3 {
    font-size: 12px !important;
  }
  h5 {
    font-size: 10px !important;
  }

  .center-print {
    background: #ffffff !important;
  }
  img {
    width: 230px !important;
    height: 50px !important;
  }
  .image-print {
    width: 120px !important;
    height: 120px !important;
    text-align: center !important;
    margin: auto !important;
  }
  span {
    font-size: 12px !important;
    font-weight: bolder !important;
  }
  .center-print {
    text-align: center !important;
  }
}
</style>
// "vue-cli-plugin-electron-builder": "git://github.com/spuky/vue-cli-plugin-electron-builder#b6826fc", //"productName": "تطبيق المالية",
