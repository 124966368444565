<template>
  <div>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>صفحة تقارير الاقسام حسب التاريخ</h3>
          </v-col>
          <v-col cols="2" align="left">
            <CostSectionSearch style="display: inline-block" @clicked="onSearch" />

            <v-btn style="display: inline-block" class="mx-2" @click="exportExcel()" fab small color="primary">
              <v-icon> mdi-printer </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="items" :items-per-page="15" :search="search" item-key="idStudent" class="table-content">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.cost`]="{ item }">
            <p style="padding: 0 3px">
              {{
                (item.cost * 1).toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </p>
          </template>
        </v-data-table>
        <h3 style="padding: 10px">
          المجموع :
          {{
            items
              .reduce((n, { cost }) => n + cost * 1, 0)
              .toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
          }}
        </h3>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import CostSectionSearch from "../components/CostSectionSearch.vue";
const Excel = require("exceljs");
export default {
  components: {
    CostSectionSearch,
  },
  data: () => ({
    search: "",
    overlay: false,
    items: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "القسم", value: "sectionName" },
      { text: "المبلغ المقبوض", value: "cost" },
    ],
  }),

  methods: {
    exportExcel() {
      let self = this;
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet("الاقساط المدفوعة");

      worksheet.views = [{ rightToLeft: true }];

      worksheet.columns = [
        { header: "القسم", key: "sectionName", width: 50 },
        { header: "المبلغ المقبوض", key: "cost", width: 50 },
      ];

      self.items.forEach((section) => {
        worksheet.addRow({
          sectionName: section.sectionName,
          cost: section.cost,
        });
      });
      worksheet.eachRow(function (row, rowNumber) {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = {
          type: "pattern",
          pattern: "darkTrellis",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `sectionReport.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
    onSearch(value) {
      let self = this;
      self.datesSelected = value.dates;
      self.items = [];
      self.items = value.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-data tr th,
.table-data tr td,
.table-data {
  text-align: center !important;
  border: 1px solid #ccc !important;
}

.printable-table {
  display: none !important;
}

@media print {
  @page {
    size: A5 landscape !important;
    margin: 0.1cm;
  }
  @page :left {
    margin: 0.1cm !important;
  }

  @page :right {
    margin: 0.1cm !important;
  }
  .not-printable {
    display: none !important;
  }
  th,
  td {
    font-size: 12px;
  }
  .printable-table {
    display: block !important;
  }
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
  body {
    -webkit-print-color-adjust: exact !important;
    height: 100% !important;
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
    color: #000;
    background-color: #fff !important;
  }
  .adjust-main {
    padding: 0px !important;
  }
  header nav,
  footer {
    display: none;
  }
  h3 {
    font-size: 12px !important;
  }
  h5 {
    font-size: 10px !important;
  }

  #barcode {
    width: 200px !important;
    height: 30px !important;
  }

  .center-print {
    background: #ffffff !important;
  }

  img {
    width: 230px !important;
  }
  .image-print {
    width: 120px !important;
    height: 120px !important;
    text-align: center !important;
    margin: auto !important;
  }
  span {
    font-size: 12px !important;
    font-weight: bolder !important;
  }
  .center-print {
    text-align: center !important;
  }
}
</style>
