<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>صفحة المصروفات السابقة</h3>
          </v-col>
          <v-col cols="2" align="left">
            <SearchOldExpenses :types="expensesTypes" :boxes="expensesBoxes" style="display: inline-block" @clicked="onSearch" />
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="items" :search="search" :items-per-page="15" item-key="idOldExpenses" class="table-content" :no-data-text="dataMessage">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.oldExpensesAmount`]="{ item }">
            {{
              item.oldExpensesAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import SearchOldExpenses from "../components/SearchOldExpenses.vue";
export default {
  components: {
    SearchOldExpenses,
  },
  data: () => ({
    search: "",
    selected: [],
    overlay: false,
    items: [],
    dataMessage: "الرجاء البحث عن مصروفات",
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "رقم السند", value: "oldExpensesNumber" },
      { text: "التبويب", value: "oldExpensesType" },
      { text: "الملاحظات", value: "oldExpensesNote" },
      { text: "تاريخ الصرفية", value: "oldDate" },
      { text: "الصندوق", value: "oldExpensesBoxName" },
      { text: "المبلغ", value: "oldExpensesAmount" },
    ],
    expensesTypes: [],
    expensesBoxes: [],
  }),
  created() {
    this.initialization();
  },
  methods: {
    initialization() {
      let self = this;
      self.overlay = true;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlArchive}/allOldExpensesTypes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlArchive}/allOldExpensesBoxes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlArchive}/allOldExpenses`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((expensesTypes, expensesBoxes, expenses) => {
            self.overlay = false;
            self.expensesTypes = expensesTypes.data;
            self.expensesBoxes = expensesBoxes.data;
            self.items = expenses.data;
            console.log(expenses.data);
          })
        )
        .catch((e) => {
          console.log(e);
          self.overlay = false;
        });
    },
    onSearch(value) {
      let self = this;
      if (value.length > 0) {
        console.log(value);
        self.items = value;
      } else {
        self.dataMessage = "لاتوجد بيانات";
      }
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      } else {
        return "متخرج";
      }
    },
    setStudyType: function (studyType) {
      if (studyType == 1) {
        return "صباحي";
      } else {
        return "مسائي";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
