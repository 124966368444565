<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل الاستمارة</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-text-field v-model="formApplication.formNumber" outlined label="رقم الاستمارة" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="formApplication.studentName" outlined label="اسم الطالب" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field label="تاريخ الاستمارة" outlined type="date" v-model="validDate"></v-text-field>

          <vuetify-money
            v-model="formApplication.amount"
            v-bind:label="label"
            :rules="studentRequiredFormRules"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> تعديل </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="mx-2" color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <span>تعديل الاستمارة</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    formApplication: Object,
  },
  data() {
    return {
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      show: false,
      studentName: "",

      amount: "25000",
      validDate: "",
      label: "المبلغ",
      placeholder: " ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "",
      options: {
        locale: "pt-BR",
        prefix: "IQ",
        suffix: "",
        length: 11,
        precision: 0,
      },
    };
  },
  created() {
    let currentDate = this.formApplication.applicationDateFormat.split("/");
    let validDate = `${currentDate[2]}-${currentDate[1]}-${currentDate[0]}`;
    this.validDate = validDate;
  },
  methods: {
    saveData() {
      let self = this;
      console.log(self.formApplication);
      let formApplicationData = {
        studentName: self.formApplication.studentName,
        amount: self.formApplication.amount,
        studyYearId: self.formApplication.studyYearId,
        applicationDate: self.validDate,
        formNumber: self.formApplication.formNumber,
        createdBy: self.formApplication.createdBy,
      };
      this.$http
        .put(
          `${this.$store.state.apiUrlFees}/formApplication/${self.formApplication.idFormApplication}`,

          formApplicationData,
          {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }
        )
        .then((res) => {
          console.log(res.data);
          this.show = false;
          this.$toasted.success("تم تعديل البيانات");
          this.$emit("reloadTask");
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
