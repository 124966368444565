<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل قناة القبول</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-autocomplete
            v-model="$store.state.studentDetails.acceptedTypeId"
            :items="acceptedTypes"
            :rules="studentRequiredFormRules"
            item-text="acceptedName"
            item-value="idAcceptedType"
            outlined
            label="نوع القبول"
          ></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-pencil </v-icon>
          تعديل قناة القبول
        </v-btn>
      </template>
      <span>تعديل قناة القبول</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    acceptedTypes: Array,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    discount: "",
    acceptedName: "",
  }),

  methods: {
    saveData() {
      let self = this;
      console.log(self.$store.state.studentDetails.acceptedTypeId);
      let studentInfo = {
        studentName: self.$store.state.studentDetails.studentName,
        englishName: self.$store.state.studentDetails.englishName,
        mail: self.$store.state.studentDetails.mail,
        dob: self.$store.state.studentDetails.dob,
        sectionId: self.$store.state.studentDetails.sectionId,
        nationality: self.$store.state.studentDetails.nationality,
        gender: self.$store.state.studentDetails.gender,
        studyType: self.$store.state.studentDetails.studyType,
        note: self.$store.state.studentDetails.note,
        collegeNumber: self.$store.state.studentDetails.collegeNumber,
        registerYearId: self.$store.state.studentDetails.registerYearId,
        studentStatusId: self.$store.state.studentDetails.studentStatusId,
        acceptedTypeId: self.$store.state.studentDetails.acceptedTypeId,
      };
      console.log(studentInfo);
      this.$http
        .put(`${this.$store.state.apiUrlRegistration}/student/${self.$store.state.studentDetails.idStudent}`, studentInfo, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          this.$http
            .get(`${this.$store.state.apiUrlRegistration}/student/${self.$store.state.studentDetails.idStudent}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            })
            .then((resStudent) => {
              console.log(res.data);
              this.show = false;
              self.$store.state.studentDetails = resStudent.data;
              this.$toasted.success("تم تعديل البيانات");
              this.$emit("reloadTask");
            });
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>

<!-- احمد غنام رجا عبدالواحد ايوب	 -->
