<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>جميع الطلبة</h3>
          </v-col>
          <v-col cols="2" align="left">
            <v-btn class="mx-2" color="primary" fab small @click="exportExcel()" style="display: inline-block" v-if="allStudents.length > 0">
              <v-icon color="secondary"> mdi-microsoft-excel </v-icon>
            </v-btn>
            <!-- <v-btn v-if="reportData.length > 0" style="display: inline-block" class="mx-2" @click="printReport()" fab small color="primary">
              <v-icon> mdi-printer </v-icon>
            </v-btn> -->
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container not-printable" fluid v-if="allStudents.length > 0">
      <table style="width: 100%" class="table-data" border="1">
        <tr>
          <th>التسلسل</th>
          <th>الرقم الجامعي</th>
          <th>اسم الطالب</th>
          <th>الجنس</th>
          <th>المرحلة</th>
          <th>القسم</th>
          <th>نوع الدراسة</th>
          <th>قناة القبول</th>
          <th>القسط الكلي</th>
          <th>نسبة التخفيض</th>
          <th>المبلغ المخفض</th>
          <th>المبلغ بعد التخفيض</th>
          <th>المبلغ المدفوع</th>
          <th>المبلغ المتبقي</th>
          <!-- <th>المبلغ المدفوع</th> -->
        </tr>
        <tr v-for="(item, index) in allStudents" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.collegeNumber }}</td>
          <td>{{ item.studentName }}</td>
          <td>{{ item.gender }}</td>
          <td>{{ item.level | setLevel }}</td>
          <td>{{ item.sectionName }}</td>
          <td>{{ item.studyType == 1 ? "صباحي" : "مسائي" }}</td>
          <td>{{ item.acceptedName }}</td>
          <td>
            {{
              item.sectionAmount?.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </td>
          <td>{{ item?.discount }}٪</td>
          <td>
            {{
              item?.discountAmount
                ? (item?.discountAmount?.amount).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                : 0
            }}
          </td>
          <td>
            {{
              item.sectionAmount?.amount
                ? (((100 - item?.discount) / 100) * item.sectionAmount?.amount - (item?.discountAmount?.amount ? item?.discountAmount?.amount : 0)).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                : item.sectionAmount?.amount
            }}
          </td>
          <td>
            {{
              (item.paid + (item?.recycle ? item.recycle.amount : 0)).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </td>
          <td>
            {{
              isNaN(((100 - item?.discount) / 100) * item.sectionAmount?.amount - (item?.discountAmount?.amount ? item?.discountAmount?.amount : 0) - item.paid)
                ? 0
                : (
                    ((100 - item?.discount) / 100) * item.sectionAmount?.amount -
                    (item?.discountAmount?.amount ? item?.discountAmount?.amount : 0) -
                    (item.paid + (item?.recycle ? item.recycle.amount : 0))
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
            }}
          </td>
          <!-- <td>
            {{
              item.alreadyPaid.toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </td> -->
        </tr>
      </table>
    </v-container>
  </div>
</template>

<script>
const Excel = require("exceljs");
export default {
  data: () => ({
    overlay: false,
    allStudents: [],
    paidStudents: [],
    allDiscountAmounts: [],
  }),
  created() {
    this.initialization();
  },

  methods: {
    initialization() {
      let self = this;
      self.overlay = true;
      this.$http
        .get(`${self.$store.state.apiUrlFees}/studentFeesYear`, {
          headers: { Authorization: `Bearer ${this.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.allStudents = res.data;
          self.overlay = false;
        })
        .catch((e) => {
          self.overlay = false;
          console.log(e);
        });
      // self.$http
      //   .get(`${self.$store.state.apiUrlRegistration}/UniversityStudents`, {
      //     headers: { Authorization: `Bearer ${self.$store.state.user}` },
      //   })
      //   .then((res) => {
      //     self.overlay = false;
      //     self.allStudents = res.data[1].map((student) => {
      //       return { ...student, paid: res.data[0].find((amount) => amount.collegeNumber == student.collegeNumber)?.paidAmount };
      //     });
      //     self.allDiscountAmounts = res.data[2];
      //     console.log(res.data);
      //   })
      // .catch((e) => {
      //   self.overlay = false;
      //   console.log(e);
      // });
    },
    getDiscountAmount(collegeNumber) {
      let self = this;
      let found = self.allDiscountAmounts.filter((dis) => dis.collegeNumber == collegeNumber);

      if (found.length > 0) {
        return found[0].amount;
      } else {
        return 0;
      }
    },
    getPaidAmount(collegeNumber, arrayOfData) {
      let found = arrayOfData.find((student) => student.collegeNumber == collegeNumber);

      if (found) {
        return found.paidAmount;
      } else {
        return 0;
      }
    },
    exportExcel() {
      let self = this;
      const workbook = new Excel.Workbook();

      const worksheet = workbook.addWorksheet("تقرير التخفيضات");
      worksheet.views = [{ rightToLeft: true }];

      worksheet.columns = [
        { header: "اسم الطالب", key: "studentName", width: 50 },
        { header: "الجنس", key: "gender", width: 50 },
        { header: "الرقم الجامعي", key: "collegeNumber", width: 50 },
        { header: "القسم", key: "sectionName", width: 50 },
        { header: "المرحلة", key: "level", width: 50 },
        { header: "نوع الدراسة", key: "studyType", width: 50 },
        { header: "قناة القبول", key: "acceptedName", width: 50 },
        { header: "القسط الكلي", key: "amount", width: 50 },
        { header: "نسبة التخفيض", key: "discount", width: 50 },
        { header: "المبلغ المخفض", key: "discountCost", width: 50 },
        { header: "المبلغ بعد التخفيض", key: "discountAmount", width: 50 },
        { header: "المبلغ المدفوع", key: "paid", width: 50 },
        { header: "المبلغ المتبقي", key: "remain", width: 50 },
      ];

      self.allStudents.forEach((item) => {
        worksheet.addRow({
          studentName: item.studentName,
          gender: item.gender,
          collegeNumber: item.collegeNumber,
          sectionName: item.sectionName,
          level: this.$options.filters.setLevel(item.level),
          studyType: item.studyType == 1 ? "صباحي" : "مسائي",
          acceptedName: item.acceptedName,
          amount: item.sectionAmount?.amount,
          discount: item.discount,
          discountCost: item?.discountAmount ? item?.discountAmount.amount : 0,
          discountAmount: item.sectionAmount?.amount
            ? ((100 - item?.discount) / 100) * item.sectionAmount?.amount - (item?.discountAmount?.amount ? item?.discountAmount?.amount : 0)
            : item.sectionAmount?.amount,
          paid: item.paid + (item?.recycle ? item.recycle.amount : 0),
          remain: isNaN(((100 - item?.discount) / 100) * item.sectionAmount?.amount - (item?.discountAmount?.amount ? item?.discountAmount?.amount : 0) - item.paid)
            ? 0
            : ((100 - item?.discount) / 100) * item.sectionAmount?.amount - (item?.discountAmount?.amount ? item?.discountAmount?.amount : 0) - (item.paid + (item?.recycle ? item.recycle.amount : 0)),
        });
      });

      worksheet.eachRow(function (row, rowNumber) {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = {
          type: "pattern",
          pattern: "darkTrellis",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `allStudents.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      } else {
        return "غير مرحل";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
tr,
tr td {
  text-align: center !important;
}
</style>
