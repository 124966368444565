<template>
  <div>
    <v-dialog v-model="show" persistent max-width="800px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>المستندات</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center" v-if="expenses.expensesImages == null">
            <vue-upload-multiple-image
              dragText="رفع الملفات"
              browseText="الصور"
              primaryText=""
              popupText=""
              markIsPrimaryText=""
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              :data-images="images"
              idUpload="myIdUpload"
              editUpload="myIdEdit"
              :showEdit="false"
            ></vue-upload-multiple-image>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="6" v-for="(image, index) in expenses.expensesImages" :key="index">
                <img
                  style="padding: 10px"
                  @click="downloadWithAxios(`${$store.state.apiUrlFees}/${image.imagePath}`, `${image.imagePath.split('/').pop()}`)"
                  crossOrigin="anonymous"
                  :src="`${$store.state.apiUrlFees}/${image.imagePath}`"
                  width="290"
                  height="390"
                  @contextmenu="showPop"
                />
                <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y>
                  <v-list>
                    <v-list-item @click="removeImage(image)">
                      <v-list-item-title>حذف الصورة</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveImages" v-on:keyup.enter="saveImages" v-if="expenses.expensesImages == null"> حفظ المستندات </v-btn>
          <v-btn text @click="show = false" v-on:keyup.enter="show = false" v-else> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="mx-2" color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-image-multiple </v-icon>
        </v-btn>
      </template>
      <span>المستندات</span>
    </v-tooltip>
  </div>
</template>

<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";

export default {
  props: {
    expenses: Object,
  },
  components: {
    VueUploadMultipleImage,
  },
  data() {
    return {
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      show: false,
      studentName: "",
      images: [],
      showMenu: false,
      x: 0,
      y: 0,
    };
  },
  created() {
    console.log(this.expenses);
  },
  methods: {
    downloadWithAxios(url, title) {
      this.$http({
        method: "get",
        url,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("error occured"));
    },
    forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    removeImage(image) {
      let self = this;
      console.log(image);
      this.$http
        .delete(`${this.$store.state.apiUrlFees}/expensesRequestImage/${image.idExpensesRequestImage}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          this.show = false;
          this.$toasted.success("تم حذف البيانات");
          this.$emit("reloadTask");
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
    uploadImageSuccess(formData, index, fileList) {
      console.log("data", formData, index, fileList);
      // Upload image api

      this.images = fileList;
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      //   SELECT Student.collegeNumber , COUNT(Student.acceptedTypeId) , AcceptedType.acceptedName , AcceptedType.discount , AcceptedType.idAcceptedType FROM Student JOIN StudentLevel ON StudentLevel.studentId = Student.idStudent JOIN AcceptedType ON AcceptedType.idAcceptedType = Student.acceptedTypeId JOIN Section ON Section.idSection = Student.sectionId WHERE Student.studentStatusId IN (1,4) AND StudentLevel.level = 2 AND StudentLevel.currentLevel = 1 AND Student.sectionId = 2 AND Student.acceptedTypeId = 5  GROUP BY Student.acceptedTypeId  , Student.collegeNumberSELECT Student.collegeNumber , COUNT(Student.acceptedTypeId) , AcceptedType.acceptedName , AcceptedType.discount , AcceptedType.idAcceptedType FROM Student JOIN StudentLevel ON StudentLevel.studentId = Student.idStudent JOIN AcceptedType ON AcceptedType.idAcceptedType = Student.acceptedTypeId JOIN Section ON Section.idSection = Student.sectionId WHERE Student.studentStatusId IN (1,4) AND StudentLevel.level = 3 AND StudentLevel.currentLevel = 1 AND Student.sectionId = 2 AND Student.acceptedTypeId = 39 GROUP BY Student.acceptedTypeId , Student.collegeNumber
      done();
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
    },
    showPop(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    saveImages() {
      let self = this;
      this.$http
        .post(`${this.$store.state.apiUrlFees}/uploadExpensesImage`, this.images)
        .then((response) => {
          console.log(response.data);
          let expensesImages = response.data.map((image) => {
            return {
              imagePath: image.imagePath,
              expensesRequestId: self.expenses.idExpensesRequest,
            };
          });
          console.log(expensesImages);
          this.$http
            .post(`${this.$store.state.apiUrlFees}/addMultiExpensesRequestImages`, expensesImages, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            })
            .then((res) => {
              self.show = false;
              this.$toasted.success("تم اضافة البيانات");
              this.$emit("reloadTask");
            })
            .catch((err) => this.$toasted.error(" حدث خطأ في البيانات"));
        })
        .catch((error) => this.$toasted.error(" حدث خطأ في البيانات"));
    },
  },
};
</script>

<style lang="scss" scoped>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
