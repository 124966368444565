<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="searchDialog" persistent max-width="700px" width="700px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>بحث في الطلبة</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 20px"></div>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="studentName" outlined label="اسم الطالب" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="collegeNumber" outlined label="الرقم الجامعي" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-autocomplete v-model="sectionId" :items="$store.state.sections" item-text="sectionName" item-value="idSection" outlined filled label="القسم"></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete v-model="level" :items="levels" item-text="levelName" item-value="level" outlined filled label="المرحلة"></v-autocomplete>
            </v-col>

            <v-col cols="6">
              <v-autocomplete v-model="statusId" :items="status" item-text="name" item-value="id" outlined filled label="حالة الطالب"></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="searchData" v-on:keyup.enter="searchData"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="searchDialog = true" v-bind="attrs" v-on="on">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </template>
      <span>بحث</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data: () => ({
    searchDialog: false,
    sectionId: "",
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    studentName: "",
    collegeNumber: "",
    overlay: false,
    statusId: "",
    status: [
      { name: "مستمر", id: 1 },
      { name: "محجوب", id: 2 },
      { name: "متخرج", id: 3 },
      { name: "طالب جديد", id: 4 },
      { name: "خروج بامر اداري", id: 5 },
      { name: "مؤجل", id: 6 },
      { name: "غير فعال", id: 7 },
      { name: "فعال", id: 8 },
      { name: "غير ملتحق بالدراسة", id: 9 },
    ],
    levels: [
      { levelName: "المرحلة الاولى", level: 1 },
      { levelName: "المرحلة الثانية", level: 2 },
      { levelName: "المرحلة الثالثة", level: 3 },
      { levelName: "المرحلة الرابعة", level: 4 },
      { levelName: "المرحلة الخامسة", level: 5 },
    ],
    level: "",
  }),
  created() {},
  methods: {
    searchData() {
      let self = this;
      self.overlay = true;
      let query = "";

      if (self.statusId) {
        query += `studentStatusId=${self.statusId}`;
      }

      if (self.sectionId) {
        query += `&sectionId=${self.sectionId}`;
      }

      if (self.level) {
        query += `&level=${self.level}`;
      }

      if (self.studentName) {
        query += `&studentName=${self.studentName}`;
      }

      if (self.collegeNumber) {
        query += `&collegeNumber=${self.collegeNumber}`;
      }

      self.$http
        .get(`${self.$store.state.apiUrlRegistration}/getStudents?${query}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.overlay = false;
          this.$emit("clicked", res.data);
        })
        .catch((e) => {
          console.log(e);
          self.overlay = false;
        });
      this.searchDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
