<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>ترحيل الطلبة</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-autocomplete
            v-model="toLevel"
            outlined
            label="ترحيل للمرحلة"
            item-text="levelName"
            item-value="level"
            :rules="studentRequiredFormRules"
            :items="levels"
            item-color="accent"
          ></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" outlined style="padding: 15px" color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-repeat </v-icon>
          ترحيل الطلبة
        </v-btn>
      </template>
      <span>ترحيل الطلبة</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    selectedStudents: Array,
  },
  data() {
    return {
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      show: false,
      levels: [
        { levelName: "المرحلة الاولى", level: 1 },
        { levelName: "المرحلة الثانية", level: 2 },
        { levelName: "المرحلة الثالثة", level: 3 },
        { levelName: "المرحلة الرابعة", level: 4 },
        { levelName: "المرحلة الخامسة", level: 5 },
      ],
      studentName: "",
      toLevel: "",
      amount: "25000",
      label: "المبلغ",
      placeholder: " ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "",
      options: {
        locale: "pt-BR",
        prefix: "IQ",
        suffix: "",
        length: 11,
        precision: 0,
      },
    };
  },

  methods: {
    saveData() {
      let self = this;
      if (self.toLevel) {
        let toLevelStudent = self.selectedStudents.map((student) => {
          return {
            level: self.toLevel,
            class: student.class,
            yearStudyId: 75,
            studentId: student.idStudent,
            currentLevel: 1,
            acceptedTypeId: student.acceptedTypeId,
            oldLevel: student.level,
          };
        });

        let updateOldStudent = self.selectedStudents.map((student) => {
          return {
            idStudentLevel: student.idStudentLevel,
          };
        });

        let studentLevelData = {
          update: updateOldStudent,
          add: toLevelStudent,
        };

        this.$http
          .post(`${this.$store.state.apiUrlRegistration}/addAndUpdateStudentLevel`, studentLevelData, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            this.show = false;
            this.$toasted.success("تم تعديل البيانات");
            this.$emit("reloadTask");
          })
          .catch((err) => {
            this.$toasted.error(" حدث خطأ في البيانات");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
