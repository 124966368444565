<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل قناة قبول </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-text-field v-model="studentAcceptedType.acceptedName" outlined label="اسم التخفيض" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="studentAcceptedType.discount" outlined label=" نسبة التخفيض" :rules="studentRequiredFormRules" type="number"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="mx-2" color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <span>تعديل قناة قبول</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    studentAcceptedType: Object,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    discount: "",
    acceptedName: "",
  }),

  methods: {
    saveData() {
      let self = this;

      if (self.studentAcceptedType.acceptedName && self.studentAcceptedType.discount) {
        let acceptedType = {
          acceptedName: self.studentAcceptedType.acceptedName,
          discount: self.studentAcceptedType.discount,
        };

        this.$http
          .put(
            `${this.$store.state.apiUrlRegistration}/acceptedType/${self.studentAcceptedType.idAcceptedType}`,

            acceptedType,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            this.show = false;
            this.$toasted.success("تم تعديل البيانات");
            this.$emit("reloadTask");
          })
          .catch((err) => {
            this.$toasted.error(" حدث خطأ في البيانات");
          });
      } else {
        this.$toasted.error("الرجاء ادخال ادخال جميع القيم");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
