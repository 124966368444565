<template>
  <div>
    <v-dialog v-model="showDeleteDialog" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="error">
          <v-btn icon dark @click="showDeleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> تأكيد تبطيل وصل</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin: 15px">
            <h3 style="color: #ff6b6b">هل انت متأكد من تبطيل هذا الوصل؟</h3>
            <h5>عند تبطيل هذا الوصل لن يظهر في الحسابات والتقارير</h5>
          </div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="error" text @click="confirmDelete"> تأكيد </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon @click="showDeleteDialog = true" color="primary" v-bind="attrs" v-on="on"> mdi-close </v-icon>
      </template>
      <span>تبطيل !</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  data: () => ({
    datePickerAddModel: false,
    showDeleteDialog: false,
    addedDate: "",
    files: [],
  }),
  methods: {
    confirmDelete() {
      let self = this;

      console.log(self.item);
      let soft = {
        idStudentFees: self.item.idStudentFees,
        collegeNumber: self.item.collegeNumber,
        paymentTypeId: self.item.paymentTypeId,
        studentLevel: self.item.studentLevel,
        amount: self.item.amount,
        paymentDate: `${self.item.paymentDateFormat.split("/")[2]}-${self.item.paymentDateFormat.split("/")[1]}-${self.item.paymentDateFormat.split("/")[0]}`,
        notes: self.item.notes,
        sectionId: self.item.sectionId,
        studyYearId: self.item.studyYearId,
        createdBy: self.item.createdBy,
        isChecked: self.item.isChecked,
        notValid: 1,
        isReceived: self.item.isReceived,
        voucherNumber: self.item.voucherNumber,
        isNewStudent: 0,
      };

      self.$http
        .put(`${this.$store.state.apiUrlFees}/studentPayment/${self.item.idStudentFees}`, soft, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          this.$toasted.error("تم تبطيل الوصل ");
          self.showDeleteDialog = false;
          this.$emit("reloadTask");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
