<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-overlay :absolute="absolute" :value="overlay"> <v-progress-circular indeterminate size="64"></v-progress-circular> </v-overlay>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة وصل جديد</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-autocomplete
            v-model="paymentTypeId"
            outlined
            label="نوع الوصل"
            :rules="studentRequiredFormRules"
            :items="$store.state.paymentTypesNotInstallment"
            item-text="typeName"
            item-value="idPaymentType"
          ></v-autocomplete>

          <vuetify-money
            v-model="amount"
            v-bind:label="label"
            :rules="studentRequiredFormRules"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
          />

          <v-textarea v-model="payNote" outlined name="input-7-4" label="الملاحظات"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
          اضافة وصل للطالب
        </v-btn>
      </template>
      <span>اضافة وصل</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    studentPay: Object,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    payTypeId: "",
    checkNumber: "",
    amount: 0,
    payNote: "لايوجد",
    paymentTypeId: "",
    feesPay: "",
    absolute: true,
    overlay: false,

    amount: "0",
    label: "المبلغ",
    placeholder: " ",
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "IQ",
      suffix: "",
      length: 11,
      precision: 0,
    },
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      let currentDate = new Date();
      console.log(currentDate.getDate());
    },
    saveData() {
      let self = this;
      self.overlay = true;
      if (self.amount && self.paymentTypeId) {
        self.$http
          .get(`${self.$store.state.apiUrlFees}/lastStudentPayment`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((response) => {
            console.log(response.data);
            let voucherNumber;
            if (response.data.voucherNumber > 0) {
              voucherNumber = response.data.voucherNumber + 1;
            } else {
              voucherNumber = 1;
            }
            let currentDate = new Date();
            console.log(currentDate);
            let foundYear = self.$store.state.years.find((year) => year.year.split("-")[0] == currentDate.getFullYear());
            let fullDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
            console.log(voucherNumber);
            let studentFees = {
              collegeNumber: self.$store.state.subFeeStudentDetails.collegeNumber,
              paymentTypeId: self.paymentTypeId,
              studentLevel: self.$store.state.subFeeStudentDetails.level,
              amount: self.amount,
              paymentDate: fullDate,
              notes: self.payNote,
              sectionId: self.$store.state.subFeeStudentDetails.sectionId,
              studyYearId: 75,
              createdBy: self.$store.state.userData.idEmployee,
              isChecked: false,
              notValid: false,
              isReceived: false,
              voucherNumber: voucherNumber,
              isNewStudent: 0,
            };

            this.$http
              .post(`${this.$store.state.apiUrlFees}/addStudentPayment`, studentFees, {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              })
              .then((res) => {
                self.overlay = true;
                this.show = false;
                this.$toasted.success("تم اضافه البيانات");
                this.$emit("reloadTask");
              })
              .catch((err) => {
                console.log(err);
                self.overlay = true;
                this.show = false;
                this.$toasted.error(" jحدث خطأ في البيانات");
              });
          })
          .catch((err) => {
            self.overlay = true;
            this.show = false;
            console.log(err);
            this.$toasted.error(" حدث خطأ في البيانات");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
