<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>صفحة مراحل الطالب</h3>
          </v-col>
          <v-col cols="2" align="left">
            <SearchStudent style="display: inline-block" @clicked="onSearch" />
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" @change="searchStudent()" v-on:keyup.enter="searchStudent()" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="students" :items-per-page="15" item-key="idStudent" class="table-content" :no-data-text="dataMessage">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            <p style="padding: 0 3px" v-if="item.studentStatusId == 3">خريج</p>
            <p v-else style="padding: 0 3px">
              {{ item.level | setLevel }}
            </p>
          </template>

          <template v-slot:[`item.studentName`]="{ item }">
            <v-btn text @click="selectStudent(item)" color="primary"> {{ item.studentName }} </v-btn>
          </template>

          <template v-slot:[`item.studyType`]="{ item }">
            <p style="padding: 0 3px">
              {{ item.studyType | setStudyType }}
            </p>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="selectStudent(item)">
              <v-icon color="primary"> mdi-check-circle </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <ModifiedLevel v-if="showModifiedLevel" :studentLevels="levels" @deleted="onDelete" />
  </div>
</template>

<script>
import SearchStudent from "../components/SearchStudent.vue";
import ModifiedLevel from "../components/ModifiedLevel.vue";
export default {
  components: {
    SearchStudent,
    ModifiedLevel,
  },
  data: () => ({
    search: "",
    selected: [],
    levels: [],
    showModifiedLevel: false,
    studentSelected: "",
    overlay: false,
    students: [],
    dataMessage: "الرجاء البحث عن طلبة",
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "اسم الطالب", value: "studentName" },
      { text: "القسم", value: "sectionName" },
      { text: "المرحلة", value: "level" },
      { text: "الرقم الجامعي", value: "collegeNumber" },
      { text: "حالة الطالب", value: "statusName" },
      { text: "جنس الطالب", value: "gender" },
      {
        text: "نوع الدراسة",
        value: "studyType",
      },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {},
  methods: {
    onSearch(value) {
      let self = this;
      if (value.length > 0) {
        console.log(value);
        self.students = value;
      } else {
        self.dataMessage = "لايوجد طلبة في القسم";
      }
    },
    onDelete() {
      let self = this;
      this.$http
        .get(`${self.$store.state.apiUrlRegistration}/allStudentLevels/${self.studentSelected.idStudent}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res);
          self.students = [];
          self.levels = res.data;
          self.showModifiedLevel = true;
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
    searchStudent() {
      let self = this;
      self.overlay = true;
      if (self.search) {
        self.$http
          .get(`${self.$store.state.apiUrlRegistration}/getStudentsBySearchBox/${self.search}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            self.overlay = false;
            self.students = res.data;
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
          });
      } else {
        self.overlay = false;
      }
    },
    addFess() {
      console.log(this.selected);
      if (this.selected.length > 0) {
        this.$store.state.studentPay = this.selected[0];
        this.$router.push("/fees").catch((error) => {});
      } else {
        this.$toasted.error("الرجاء اختيار طالب ");
      }
    },
    selectStudent(student) {
      let self = this;
      self.studentSelected = student;
      this.$http
        .get(`${self.$store.state.apiUrlRegistration}/allStudentLevels/${student.idStudent}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res);
          self.students = [];
          self.levels = res.data;
          self.showModifiedLevel = true;
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      } else {
        return "متخرج";
      }
    },
    setStudyType: function (studyType) {
      if (studyType == 1) {
        return "صباحي";
      } else {
        return "مسائي";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
