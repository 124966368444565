<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="searchDialog" persistent max-width="700px" width="700px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>انشاء تقرير</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 20px"></div>
          <v-row>
            <v-col>
              <v-autocomplete v-model="sectionId" :items="$store.state.sections" item-text="sectionName" item-value="idSection" outlined label="القسم"></v-autocomplete>

              <v-autocomplete v-model="acceptedTypeId" :items="this.$store.state.acceptedTypes" item-text="acceptedName" item-value="idAcceptedType" outlined label="قناة القبول"></v-autocomplete>

              <v-autocomplete v-model="studentLevel" :items="allStudentLevels" item-text="levelName" item-value="level" outlined label="المرحلة"></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="searchData" v-on:keyup.enter="searchData"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="searchDialog = true" v-bind="attrs" v-on="on">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </template>
      <span>بحث</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchDialog: false,
      typeId: "",
      sectionId: "",
      acceptedTypeId: "",
      studentLevel: "",
      overlay: false,
      allStudentLevels: [],
    };
  },
  created() {
    this.allStudentLevels = [{ level: 999, levelName: "جميع المراحل عدا الاول" }, ...this.$store.state.levels];
  },
  methods: {
    searchData() {
      let self = this;
      let query = "";

      if (self.acceptedTypeId) {
        if (self.studentLevel) {
          query += `&level=${self.studentLevel}`;
        }

        if (self.sectionId) {
          query += `&sectionId=${self.sectionId}`;
        }

        self.$http
          .get(`${self.$store.state.apiUrlFees}/studentDiscountReport?acceptedTypeId=${self.acceptedTypeId}${query}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            self.overlay = false;
            console.log(res.data);
            this.searchDialog = false;
            this.$emit("clicked", res.data);
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
            this.searchDialog = false;
          });
      } else {
        this.$toasted.error("الرجاء اختيار المحددات");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
