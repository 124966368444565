<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة قيمة للتخفيض</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-file-input multiple accept="image/*" label="المستند" outlined v-model="files" @change="getAllFiles"></v-file-input>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn class="mx-2" dark color="primary" @click="show = true"> <v-icon dark> mdi-file-image-plus </v-icon> اضافة مستند</v-btn>
  </div>
</template>

<script>
export default {
  props: {
    collegeNumber: String,
  },
  data() {
    return {
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      show: false,
      files: [],
    };
  },

  methods: {
    saveData() {
      let self = this;
      if (self.studentImage) {
        let data = {
          collegeNumber: self.collegeNumber,
          photoPath: self.studentImage,
          createdBy: self.$store.state.userData.idEmployee,
        };

        console.log(data);

        this.$http
          .post(`${this.$store.state.apiUrlFees}/addStudentProfilePhoto`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            this.show = false;
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          })
          .catch((err) => {
            this.$toasted.error(" حدث خطأ في البيانات");
          });
      } else {
        this.$toasted.error(" حدث خطأ في البيانات");
      }
    },
    getAllFiles() {
      let self = this;

      if (self.files.length > 0) {
        console.log(self.files);
        self.overlay = true;
        let formData = new FormData();
        formData.append("files", self.files[0]);

        console.log(self.files[0]);
        self.$http.post(`${self.$store.state.apiUrlFees}/uploadProfilePhoto`, formData).then((res) => {
          self.overlay = false;
          this.$toasted.success("تم اضافه المستند");
          self.studentImage = res.data.imagePath;
          console.log(res);
        });
      } else {
        console.log("not valid", self.files);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
