<template>
  <div>
    <v-dialog v-model="showEditDialog" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="showEditDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل المذكرة الداخلية</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="6">
              <v-text-field outlined v-model="dataToEdit.from.sectionName" label="من" disabled></v-text-field>
              <v-autocomplete
                v-model="dataToEdit.archiveSubjectId"
                :items="$store.state.subjects"
                item-text="subjectName"
                item-value="idArchiveSubject"
                outlined
                filled
                label="الموضوع"
              ></v-autocomplete>

              <v-text-field v-model="dataToEdit.archiveDate" type="date" label="تاريخ الكتاب" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined v-model="dataToEdit.to.sectionName" label="الى" disabled></v-text-field>

              <v-text-field outlined v-model="dataToEdit.archiveNumber" label="العدد"></v-text-field>

              <v-file-input multiple label="الملفات" outlined v-model="files" @change="getAllFiles"></v-file-input>
            </v-col>
          </v-row>

          <v-textarea outlined rows="3" row-height="15" name="input-7-4" v-model="dataToEdit.subjectDescription" label="نص الكتاب "></v-textarea>

          <v-textarea rows="3" row-height="15" outlined v-model="dataToEdit.note" name="input-7-4" label="الملاحظات"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="confirmEdit"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn icon @click="showEditDialog = true">
      <v-icon color="primary"> mdi-pencil </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    dataToEdit: Object,
  },
  data: () => ({
    datePickerAddModel: false,
    showEditDialog: false,
    addedDate: "",
    files: [],
  }),
  created() {
    console.log(this.dataToEdit.archiveDate);
    let dateFormat = this.dataToEdit.archiveDateFormatted.split("/");
    console.log(dateFormat);
    this.dataToEdit.archiveDate = `${dateFormat[2]}-${dateFormat[1]}-${dateFormat[0]}`;
  },

  methods: {
    getAllFiles() {
      let self = this;
      if (this.files.length > 0) {
        let formData = new FormData();
        self.files.forEach((file, index) => {
          console.log(index);
          formData.append("files", file);
        });
        self.$http.post(`${self.$store.state.apiUrlArchive}/uploadArchive`, formData).then((res) => {
          self.archiveImages = res.data;
          console.log(res);
        });
      }
    },
    getDates() {
      this.datePickerSearchModel = false;
      this.datePickerAddModel = false;
    },
    confirmEdit() {
      let self = this;
      console.log(self.dataToEdit);

      if (self.dataToEdit.archiveDate && self.dataToEdit.archiveNumber && self.dataToEdit.idArchiveSubject && self.dataToEdit.subjectDescription) {
        self.dataToEdit.archiveDate = new Date(self.dataToEdit.archiveDate).toISOString();
        self.dataToEdit.from = self.dataToEdit.from.idSection;
        self.dataToEdit.to = self.dataToEdit.to.idSection;
        self.dataToEdit.archiveDate = `${new Date(self.dataToEdit.archiveDate).getFullYear()}-${new Date(self.dataToEdit.archiveDate).getMonth() + 1}-${new Date(
          self.dataToEdit.archiveDate
        ).getDate()}`;

        self.dataToEdit.incomeDate = new Date(self.dataToEdit.incomeDate).toISOString();

        self.dataToEdit.incomeDate = `${new Date(self.dataToEdit.incomeDate).getFullYear()}-${new Date(self.dataToEdit.incomeDate).getMonth() + 1}-${new Date(self.dataToEdit.incomeDate).getDate()}`;

        self.$http.put(`${self.$store.state.apiUrlArchive}/archive/${self.dataToEdit.idArchive}`, self.dataToEdit).then((response) => {
          this.$emit("reloadTask");
          this.$toasted.success("تم تعديل البيانات");
          self.showEditDialog = false;
        });
      } else {
        this.$toasted.success("الرجاء ملئ كافة الحقول");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
