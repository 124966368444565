<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل معلومات الايراد</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-text-field label="تاريخ الايراد" outlined type="date" v-model="validDate"></v-text-field>

          <v-textarea outlined name="input-7-4" label="الملاحظات" v-model="receipt.notes"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon @click="show = true" v-bind="attrs" v-on="on">
          <v-icon color="primary"> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <span>تعديل معلومات الايراد</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    receipt: Object,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    validDate: "",

    amount: "0",
    label: "المبلغ",
    placeholder: " ",
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "IQ",
      suffix: "",
      length: 11,
      precision: 0,
    },
  }),
  created() {
    let currentDate = this.receipt.receiptDateFormat.split("/");
    let validDate = `${currentDate[2]}-${currentDate[1]}-${currentDate[0]}`;
    this.validDate = validDate;
  },
  methods: {
    saveData() {
      let self = this;

      let receipt = {
        receiptDate: self.validDate,
        amount: self.receipt.amount,
        notes: self.receipt.notes,
        receiptTypeId: self.receipt.receiptTypeId,
        createdBy: self.receipt.createdBy,
        boxId: self.receipt.boxId,
        isInternal: self.receipt.isInternal,
        studyYearId: self.receipt.studyYearId,
        employeeId: self.receipt.employeeId,
      };

      this.$http
        .put(`${this.$store.state.apiUrlFees}/receipt/${this.receipt.idReceipt}`, receipt, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          this.show = false;
          this.$toasted.success("تم اضافه البيانات");
          this.$emit("reloadTask");
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
