<template>
  <div>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>صفحة التقارير الطلبة</h3>
          </v-col>
          <v-col cols="2" align="left">
            <AddCustomPayment style="display: inline-block" @clicked="addElement" />
            <AddCustomHeaderTable style="display: inline-block" @clicked="addHeaderTable" />
            <v-btn style="display: inline-block" class="mx-2" @click="printReport()" fab small color="primary">
              <v-icon> mdi-printer </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container not-printable" fluid>
      <v-container class="container" fluid>
        <div>
          <!-- <h3 style="text-align: center">من تاريخ {{ dates[0] }} الى تاريخ {{ dates[1] }}</h3> -->
        </div>
        <table style="width: 100%" class="table-data">
          <tr>
            <th>التسلسل</th>
            <th>رقم الوصل</th>
            <th>الرقم الجامعي</th>
            <th>اسم الطالب</th>
            <th>القسم</th>
            <th>المرحلة</th>
            <th>تاريخ الوصل</th>
            <th>تسديد عن</th>
            <th>المبلغ</th>
            <th>الملاحظات</th>
          </tr>
          <tr v-for="(item, index) in payments" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.voucherNumber }}</td>
            <td>{{ item.collegeNumber }}</td>
            <td>{{ item.studentName }}</td>
            <td>{{ item.sectionName }}</td>
            <td>{{ item.studentLevel }}</td>
            <td>{{ item.voucherDate }}</td>
            <td>{{ item.paymentFor }}</td>
            <td>
              {{
                item.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </td>
            <td>{{ item.notes }}</td>
          </tr>
        </table>
      </v-container>
      <h5 style="padding: 20px; font-size: 12px">
        المجموع الكلي :
        {{
          getTotalMoney(payments).toLocaleString("en-US", {
            style: "currency",
            currency: "IQD",
          })
        }}
      </h5>
    </v-container>
  </div>
</template>

<script>
import AddCustomPayment from "./../components/AddCustomPayment.vue";
import AddCustomHeaderTable from "./../components/AddCustomHeaderTable.vue";
export default {
  components: {
    AddCustomPayment,
    AddCustomHeaderTable,
  },
  data() {
    return {
      payments: [],
      header: "",
    };
  },
  methods: {
    addElement(value) {
      this.payments.push(value);
    },
    addHeaderTable(value) {
      this.header = value;
    },
    getTotalMoney(arrayOfData) {
      if (arrayOfData) {
        let total = arrayOfData.reduce((n, { amount }) => n + amount, 0);
        return total;
      } else {
        return 0;
      }
    },

    printReport() {
      let self = this;
      const WinPrint = window.open("", "", "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0");

      WinPrint.document.write(`<!DOCTYPE html>
        <!DOCTYPE html>
          
        <html lang="ar">
        <style>
         
        @media print {
          table { page-break-inside:auto }
          tr    { page-break-inside:avoid; page-break-after:auto }
          @page { margin: 0px !important; }
          body { margin: 1cm !important; }
          .pagebreak {
            clear: both;page-break-after: always;
          }
        }
          body {
            direction : rtl !important;
          }
          table {
            width : 98% !important;
          }
          tr,tr td,th{
            text-align : center !important;
            font-size : 12px !important;
            padding : 5px !important;
          }
        </style>
        <body>
              ${self.printTableData()}
              <h5 style="padding: 20px; font-size: 12px">
              المجموع الكلي :
              ${self.getTotalMoney(self.payments).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })}
            </h5>
        </body>`);

      self.voucherIndex = 0;
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },

    printTableData() {
      let self = this;
      let tableData = "";

      tableData += `
        <h3 style="text-align:center">${self.header.twoDates} / ${self.header.employeeName}</h3>
        <table border='1' style="padding:3px;margin: auto !important;">
            <tr>
                <th>التسلسل</th>
                <th>رقم الوصل</th>
                <th>الرقم الجامعي</th>
                <th>اسم الطالب</th>
                <th>القسم</th>
                <th>المرحلة</th>
                <th>تاريخ الوصل</th>
                <th>تسديد عن</th>
                <th>المبلغ</th>
              </tr>
              ${self.rowTable(self.payments)}
          </table>
          
          <h5 style="padding: 10px; font-size: 12px">
              المجموع :
              ${self.getTotalMoney(self.payments).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })}
            </h5>

          `;

      return tableData;
    },
    rowTable(arrayOfData) {
      let self = this;
      let tdData = "";
      for (let j = 0; j < arrayOfData.length; j++) {
        self.voucherIndex += 1;
        tdData += `
              <tr>
                <td>${self.voucherIndex}</td>
                <td>${arrayOfData[j].voucherNumber}</td>
                <td>${arrayOfData[j].collegeNumber}</td>
                <td>${arrayOfData[j].studentName}</td>
                <td>${arrayOfData[j].sectionName}</td>
                <td>${arrayOfData[j].studentLevel}</td>
                <td>${arrayOfData[j].voucherDate}</td>
                <td>${arrayOfData[j].paymentFor}</td>
                <td style="font-size:12px">${arrayOfData[j].amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })}</td>
              </tr>
            
          `;
      }
      return tdData;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-data tr th,
.table-data tr td,
.table-data {
  text-align: center !important;
  border: 1px solid #ccc !important;
}

.printable-table {
  display: none !important;
}

@media print {
  @page {
    size: A5 landscape !important;
    margin: 0.1cm;
  }
  @page :left {
    margin: 0.1cm !important;
  }

  @page :right {
    margin: 0.1cm !important;
  }
  .not-printable {
    display: none !important;
  }
  th,
  td {
    font-size: 12px;
  }
  .printable-table {
    display: block !important;
  }
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
  body {
    -webkit-print-color-adjust: exact !important;
    height: 100% !important;
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
    color: #000;
    background-color: #fff !important;
  }
  .adjust-main {
    padding: 0px !important;
  }
  header nav,
  footer {
    display: none;
  }
  h3 {
    font-size: 12px !important;
  }
  h5 {
    font-size: 10px !important;
  }

  #barcode {
    width: 200px !important;
    height: 30px !important;
  }

  .center-print {
    background: #ffffff !important;
  }

  img {
    width: 230px !important;
  }
  .image-print {
    width: 120px !important;
    height: 120px !important;
    text-align: center !important;
    margin: auto !important;
  }
  span {
    font-size: 12px !important;
    font-weight: bolder !important;
  }
  .center-print {
    text-align: center !important;
  }
}
</style>
