<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-overlay :absolute="absolute" :value="overlay"> <v-progress-circular indeterminate size="64"></v-progress-circular> </v-overlay>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة وصل جديد</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="6">
              <h4>قسط القسم : {{ sectionInstallment }}</h4></v-col
            >
          </v-row>

          <div style="margin-top: 10px"></div>

          <v-autocomplete
            v-model="paymentTypeId"
            outlined
            label="الدفعة"
            :rules="studentRequiredFormRules"
            :items="paymentTypesAll"
            item-text="typeName"
            item-value="idPaymentType"
            @change="identityChosen()"
          ></v-autocomplete>

          <vuetify-money
            v-model="amount"
            v-bind:label="label"
            :rules="studentRequiredFormRules"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
          />

          <v-textarea v-model="payNote" outlined name="input-7-4" label="الملاحظات"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
          تسديد جديد
        </v-btn>
      </template>
      <span>اضافة وصل</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    studentPay: Object,
    sectionId: Number,
    sectionInstallment: Number,
    remaining: Number,
    paymentTypesAll: Array,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    payTypeId: "",
    checkNumber: "",
    amount: 0,
    paymentTypesInstallment: [],
    payNote: "لايوجد",
    paymentTypeId: "",
    feesPay: "",
    absolute: true,
    overlay: false,

    amount: "0",
    label: "المبلغ",
    placeholder: " ",
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "IQ",
      suffix: "",
      length: 11,
      precision: 0,
    },
  }),
  created() {
    this.initialData();
    // $store.state.paymentTypesInstallment
    console.log(this.$store.state.paymentTypesAll);
  },
  methods: {
    initialData() {
      let self = this;
      let currentDate = new Date();
      console.log(currentDate.getDate());
    },

    identityChosen() {
      let self = this;
      console.log(self.paymentTypeId);
      if (self.paymentTypeId == 24) {
        self.amount = 50000;
      } else {
        self.amount = 0;
      }
    },

    saveData() {
      let self = this;
      self.overlay = true;
      if (self.amount && self.paymentTypeId) {
        self.$http
          .get(`${self.$store.state.apiUrlFees}/lastStudentPayment`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((response) => {
            console.log(response.data);
            let voucherNumber;
            if (response.data.voucherNumber > 0) {
              voucherNumber = response.data.voucherNumber + 1;
            } else {
              voucherNumber = 1;
            }
            let currentDate = new Date();
            console.log(currentDate);
            let fullDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
            console.log(voucherNumber);
            let studentFees = {
              collegeNumber: self.$store.state.studentLevelOne.collegeNumber,
              paymentTypeId: self.paymentTypeId,
              studentLevel: 1,
              amount: self.amount,
              paymentDate: fullDate,
              notes: self.payNote,
              sectionId: self.sectionId,
              studyYearId: 75,
              createdBy: self.$store.state.userData.idEmployee,
              isChecked: false,
              notValid: false,
              isReceived: false,
              voucherNumber: voucherNumber,
              isNewStudent: 1,
            };

            this.$http
              .post(`${this.$store.state.apiUrlFees}/addStudentPayment`, studentFees, {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              })
              .then((res) => {
                self.overlay = false;
                this.show = false;
                this.$toasted.success("تم اضافه البيانات");
                this.$emit("reloadTask");
              })
              .catch((err) => {
                console.log(err);
                self.overlay = false;
                this.show = false;
                self.paymentTypeId = "";
                self.amount = 0;

                this.$toasted.error(" jحدث خطأ في البيانات");
              });
          })
          .catch((err) => {
            self.overlay = true;
            this.show = false;
            console.log(err);
            this.$toasted.error(" حدث خطأ في البيانات");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
