<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة ملاحظة جديدة</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          الملاحظة المضافة ستظهر تحت كل مرحلة للطالب
          <div style="margin-top: 10px"></div>

          <v-textarea v-model="notes" outlined label="الملاحظات" :rules="studentRequiredFormRules"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab x-small dark color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة ملاحظة جديدة</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    collegeNumber: String,
    yearStudyId: Number,
  },
  data() {
    return {
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      show: false,
      notes: "",
    };
  },

  methods: {
    saveData() {
      let self = this;
      console.log(self.collegeNumber, self.yearStudyId);

      if (self.notes) {
        let data = {
          collegeNumber: self.collegeNumber,
          yearStudyId: self.yearStudyId,
          notes: self.notes,
        };

        this.$http
          .post(`${this.$store.state.apiUrlRegistration}/addLevelNote`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            this.show = false;
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          })
          .catch((err) => {
            this.$toasted.error(" حدث خطأ في البيانات");
          });
      } else {
        this.$toasted.error("لا يمكن ترك الحقل فارغ");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
