<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تغيير حالة الطالب</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 20px"></div>
          <v-autocomplete v-model="studentStatusId" :items="studentStatus" item-text="statusName" item-value="idStudentStatus" outlined label="الحالة"></v-autocomplete>
          <div style="margin-bottom;: 20px"></div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" outlined style="padding: 15px" color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-share-all-outline </v-icon>
          حالة الطالب
        </v-btn>
      </template>
      <span>تعيير حالة الطالب</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    selectedStudents: Array,
  },
  data() {
    return {
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      show: false,
      studentStatusId: "",
      levels: [
        { levelName: "المرحلة الاولى", level: 1 },
        { levelName: "المرحلة الثانية", level: 2 },
        { levelName: "المرحلة الثالثة", level: 3 },
        { levelName: "المرحلة الرابعة", level: 4 },
        { levelName: "المرحلة الخامسة", level: 5 },
      ],
      studentStatus: [],
      studentName: "",
      toLevel: "",
      amount: "25000",
      label: "المبلغ",
      placeholder: " ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "",
      options: {
        locale: "pt-BR",
        prefix: "IQ",
        suffix: "",
        length: 11,
        precision: 0,
      },
    };
  },
  // 222100878588
  created() {
    this.initialization();
  },
  methods: {
    initialization() {
      let self = this;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlRegistration}/allStudentStatus`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((studentStatus) => {
            self.studentStatus = studentStatus.data;
          })
        )
        .catch((e) => console.log(e));
    },
    saveData() {
      let self = this;

      let updateOldStudent = self.selectedStudents.map((student) => {
        return {
          idStudent: student.idStudent,
        };
      });

      console.log(updateOldStudent);

      this.$http
        .put(
          `${this.$store.state.apiUrlRegistration}/multiStudents`,
          { updateOldStudent: updateOldStudent, studentStatusId: self.studentStatusId },
          {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }
        )
        .then((res) => {
          this.show = false;
          this.$toasted.success("تم تعديل البيانات");
          this.$emit("reloadTask");
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
