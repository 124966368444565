<template>
  <div>
    <v-dialog v-model="showImageDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="showImageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>المرفقات</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin: 15px">
            <v-row>
              <h3 v-if="images == null">لا توجد مرفقات</h3>
              <v-col cols="6" v-for="(image, index) in images" :key="index">
                <img v-if="!getFileInfo(image)" crossOrigin="anonymous" @click="fullScreenImage($event)" :src="`${$store.state.apiUrlArchive}/${image.imagePath}`" width="100%" />
                <vue-pdf-embed v-else :source="`${$store.state.apiUrlArchive}/${image.imagePath}`" @click="fullScreenImage($event)" />
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="showImageDialog = false"> موافق </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-icon @click="showImageDialog = true" color="primary"> mdi-image-area </v-icon>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  components: {
    VuePdfEmbed,
  },
  props: {
    images: Array,
  },
  data: () => ({
    datePickerAddModel: false,
    showImageDialog: false,
    addedDate: "",
    files: [],
  }),
  methods: {
    confirmDelete() {
      let self = this;
      self.$http.delete(`${self.$store.state.apiUrlArchive}/archive/${self.dataToDelete.idArchive}`).then((res) => {
        console.log(res.data);
        this.$toasted.error("تم حذف البيانات");
        self.showDeleteDialog = false;
        this.$emit("reloadTask");
      });
    },
    getFileInfo(file) {
      let isPdf = file.imagePath.includes(".pdf");
      console.log(isPdf);
      return isPdf;
    },
    fullScreenImage(e) {
      const doc = new jsPDF();

      const getBase64StringFromDataURL = (dataURL) => dataURL.replace("data:", "").replace(/^.+,/, "");

      const canvas = document.createElement("canvas");

      canvas.width = e.target.naturalWidth;
      canvas.height = e.target.naturalHeight;

      canvas.getContext("2d").drawImage(e.target, 0, 0);

      const dataURL = canvas.toDataURL();

      const base64 = getBase64StringFromDataURL(dataURL);

      doc.addImage(base64, 0, 0, 210, 300);
      doc.save("document.pdf");

      canvas.remove();

      // let imageWindow = window.open(
      //   "",
      //   "",
      //   "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0"
      // );

      // imageWindow.document.write(`<!DOCTYPE html>
      //             <html>
      //             <head></head>
      //             <body></body> <img src="${this.$store.state.apiUrl}/${image.imagePath}"  style="margin-top: 10px;"></html>`);

      // setTimeout(() => {
      //   imageWindow.document.close();
      //   imageWindow.focus();
      //   imageWindow.print();
      //   imageWindow.close();
      // }, 500);
      // //  setTimeout(function () {
      // //  }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
