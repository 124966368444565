<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="searchDialog" persistent max-width="700px" width="700px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>بحث</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 20px"></div>
          <v-row>
            <v-col cols="6">
              <v-autocomplete v-model="box" :items="boxes" item-text="oldReceiptBoxName" item-value="oldReceiptBoxName" outlined label="الصندوق"></v-autocomplete>
            </v-col>

            <v-col cols="6">
              <v-autocomplete v-model="from" :items="allFrom" item-text="oldReceiptFrom" item-value="oldReceiptFrom" outlined label="من صندوق"></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-dialog ref="dialog" v-model="datePickerSearchModel" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateRangeText" label="اختر التاريخ من - الى" readonly outlined v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="dates" scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="datePickerSearchModel = false"> الغاء </v-btn>
                  <v-btn text color="primary" @click="getDates"> موافق </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="searchData" v-on:keyup.enter="searchData"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="searchDialog = true" v-bind="attrs" v-on="on">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </template>
      <span>بحث</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    allFrom: Array,
    boxes: Array,
  },
  data: () => ({
    searchDialog: false,
    paymentTypeId: "",
    createdBy: "",
    box: "",
    from: "",
    datePickerSearchModel: false,
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    overlay: false,
    dates: [],
  }),
  created() {},
  methods: {
    searchData() {
      let self = this;
      self.overlay = true;

      let query = "data=1";

      if (self.dates.length > 0) {
        query += `&dates=${JSON.stringify(self.dates)}`;
      }
      if (self.from) {
        query += `&from=${JSON.stringify(self.from)}`;
      }
      if (self.box) {
        query += `&box=${JSON.stringify(self.box)}`;
      }

      self.$http
        .get(`${self.$store.state.apiUrlArchive}/searchOldReceipt?${query}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.overlay = false;
          console.log(res.data);
          this.$emit("clicked", res.data);
        })
        .catch((e) => {
          console.log(e);
          self.overlay = false;
        });
      this.searchDialog = false;
    },
    getDates() {
      this.datePickerSearchModel = false;
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.length > 0 ? this.dates.join(" - ") : "تاريخ من - الى";
    },
  },
};
</script>

<style lang="scss" scoped></style>
