<template>
  <div>
    <v-dialog v-model="show" persistent max-width="1000px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة كتاب داخلي</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-list dense style="height: 300px; overflow-y: scroll">
                <v-subheader>الاقسام</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(section, i) in $store.state.allSections" :key="i" @click="selectedSection(section)">
                    <v-list-item-icon>
                      <v-icon color="primary">{{ section.isScienceSection ? "mdi-atom" : "mdi-office-building" }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ section.sectionName }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col cols="6">
              <v-list dense style="height: 300px; overflow-y: scroll">
                <v-subheader>الاقسام المختارة</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(section, i) in chosenSections" :key="i" @click="deleteSection(section)">
                    <v-list-item-icon>
                      <v-icon color="primary">{{ section.isScienceSection ? "mdi-atom" : "mdi-office-building" }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ section.sectionName }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>

          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="6">
              <v-text-field solo disabled :value="sectionName" label="من"></v-text-field>
              <v-autocomplete v-model="archiveSubject" :items="$store.state.subjects" item-text="subjectName" item-value="idArchiveSubject" solo filled label="الموضوع"></v-autocomplete>

              <label>
                تاريخ المذكرة
                <v-text-field v-model="incomeDate" type="date" label="اختر التاريخ" solo></v-text-field>
              </label>
            </v-col>
            <v-col cols="6">
              <!-- <v-autocomplete
                          v-model="to"
                          :items="$store.state.sections"
                          item-text="sectionName"
                          item-value="idSection"
                          solo
                          filled
                          label="الى"
                       ></v-autocomplete> -->

              <v-text-field solo v-model="archiveNumber" label="العدد"></v-text-field>
              <v-file-input multiple label="الملفات" solo v-model="files" @change="getAllFiles"></v-file-input>
            </v-col>
          </v-row>

          <v-textarea solo rows="3" row-height="15" name="input-7-4" v-model="archiveDescription" label="نص المذكرة"></v-textarea>

          <v-textarea v-if="$store.state.userData.sectionId != 2" rows="3" row-height="15" solo v-model="note" name="input-7-4" label="الملاحظات"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveOuterData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة كتاب داخلي</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    datePickerAddModel: false,
    items: [],
    show: false,
    addedDate: "",
    overlay: false,
    archiveImages: [],
    chosenSections: [],
    archiveSubject: "",
    archiveDescription: "",
    note: "لايوجد",
    from: "",
    to: "",
    archiveNumber: "",
    incomeDate: "",
    yearStudyId: "",
    files: [],
    dates: [],
    sectionName: "",
  }),
  mounted() {
    this.sectionName = this.$store.state.allSections.find((section) => section.idSection == this.$store.state.userData.sectionId).sectionName;
    console.log(this.$store.state.allSections);
  },
  methods: {
    getAllFiles() {
      let self = this;
      if (this.files.length > 0) {
        self.overlay = true;
        let formData = new FormData();
        self.files.forEach((file, index) => {
          console.log(index);
          formData.append("files", file);
        });
        self.$http.post(`${self.$store.state.apiUrlArchive}/uploadArchive`, formData).then((res) => {
          self.overlay = false;
          self.archiveImages = res.data;
          console.log(res);
        });
      }
    },
    getDates() {
      this.datePickerSearchModel = false;
      this.datePickerAddModel = false;
    },
    deleteSection(chosen) {
      console.log(chosen);
      this.chosenSections = this.chosenSections.filter((section) => section.idSection != chosen.idSection);
    },
    selectedSection(section) {
      let foundSection = this.chosenSections.find((singleSection) => singleSection.idSection == section.idSection);
      if (!foundSection) {
        this.chosenSections.push(section);
      }
    },
    saveOuterData() {
      let self = this;

      if (self.archiveSubject && self.archiveNumber && self.incomeDate && self.archiveDescription && self.chosenSections.length > 0) {
        let multiInside = self.chosenSections.map((section) => {
          return {
            from: self.$store.state.userData.sectionId,
            to: section.idSection,
            archiveDate: self.incomeDate,
            archiveSubjectId: self.archiveSubject,
            archiveNumber: self.archiveNumber,
            subjectDescription: self.archiveDescription,
            note: self.note ? self.note : "لايوجد",
            sectionId: self.$store.state.userData.sectionId,
            archiveTypeId: 3,
            yearStudyId: 73,
            incomeDate: self.incomeDate,
            incomeNumber: "0",
          };
        });
        console.log(multiInside);
        self.$http.post(`${self.$store.state.apiUrlArchive}/addMultiArchive`, multiInside).then((res) => {
          console.log(self.archiveImages);
          console.log(res.data);
          if (self.archiveImages.length > 0) {
            let archiveInsideImages = [];

            let images = self.archiveImages.forEach((image) => {
              res.data.forEach((archive) => {
                let data = {
                  imagePath: image.imagePath,
                  archiveId: archive.idArchive,
                };
                archiveInsideImages.push(data);
              });
            });
            console.log(archiveInsideImages);

            self.$http.post(`${self.$store.state.apiUrlArchive}/addArchiveImages`, archiveInsideImages).then((resImages) => {
              console.log(resImages);
              this.$toasted.success("تم اضافه البيانات");
              this.$emit("reloadTask");
            });
          } else {
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          }
        });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
