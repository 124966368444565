import { render, staticRenderFns } from "./StudentDiscountReport.vue?vue&type=template&id=3780c706&scoped=true&"
import script from "./StudentDiscountReport.vue?vue&type=script&lang=js&"
export * from "./StudentDiscountReport.vue?vue&type=script&lang=js&"
import style0 from "./StudentDiscountReport.vue?vue&type=style&index=0&id=3780c706&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3780c706",
  null
  
)

export default component.exports