<template>
  <div>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-data-table :headers="headers" :items="studentLevels" :items-per-page="15" item-key="idStudent" class="table-content">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.currentLevel`]="{ item }">
            <p style="padding: 0 3px">{{ item.currentLevel == 1 ? "نعم" : "كلا" }}</p>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            <p style="padding: 0 3px">{{ item.level | setLevel }}</p>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="editLevel(item)">
              <v-icon color="success"> mdi-pencil-circle </v-icon>
            </v-btn>

            <v-btn icon @click="deleteStudentLevel(item)">
              <v-icon color="error"> mdi-delete-circle </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-dialog v-model="showDeleteLevel" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="error">
          <v-btn icon dark @click="showDeleteLevel = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> تأكيد حذف</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin: 15px">
            <h3 style="color: #ff6b6b">هل انت متأكد من حذف البيانات ؟</h3>
            <h5>عند الحذف البيانات لا يمكن استعادتها لاحقاً!</h5>
          </div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="error" text @click="confirmDelete"> حذف </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل المرحلة </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-autocomplete v-model="selectedLevel.level" outlined color="primary" label="المرحلة" :items="selectLevel" item-text="text" item-value="value" item-color="primary"></v-autocomplete>
          <v-text-field v-model="selectedLevel.class" outlined label="الشعبة"> </v-text-field>
          <v-autocomplete
            v-model="selectedLevel.yearStudyId"
            outlined
            color="primary"
            label="السنة الدراسية"
            :items="yearStudy"
            item-text="year"
            item-value="yearStudyId"
            item-color="primary"
          ></v-autocomplete>

          <v-autocomplete
            v-model="selectedLevel.currentLevel"
            outlined
            color="primary"
            label="هل المرحلة حالية ؟"
            :items="isCurrentYear"
            item-text="name"
            item-value="type"
            item-color="primary"
          ></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="editData" v-on:keyup.enter="editData"> تعديل </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    boxes: Array,
    expensesTypes: Array,
    studentLevels: Array,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    showDeleteLevel: false,
    discount: "",
    acceptedName: "",
    deleteLevel: "",
    selectedLevel: "",
    isCurrentYear: [
      { name: "نعم", type: 1 },
      { name: "لا", type: 0 },
    ],
    yearStudy: [
      { year: "2020-2021", yearStudyId: 71 },
      { year: "2021-2022", yearStudyId: 72 },
      { year: "2022-2023", yearStudyId: 73 },
      { year: "2023-2024", yearStudyId: 74 },
      { year: "2024-2025", yearStudyId: 75 },
    ],
    selectLevel: [
      {
        text: "المرحلة الاولى",
        value: 1,
      },
      {
        text: "المرحلة الثانية",
        value: 2,
      },
      {
        text: "المرحلة الثالثة",
        value: 3,
      },
      {
        text: "المرحلة الرابعة",
        value: 4,
      },
      {
        text: "المرحلة الخامسة",
        value: 5,
      },
    ],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "المرحلة", value: "level" },
      { text: "الشعبة", value: "class" },
      { text: "السنة الدراسية", value: "year" },
      { text: "هل المرحلة هي الحالية", value: "currentLevel" },
      { text: "الاجراءات", value: "actions" },
    ],
  }),

  methods: {
    editData() {
      let self = this;
      console.log(this.selectedLevel);
      this.$http
        .put(`${this.$store.state.apiUrlRegistration}/studentLevel/${self.selectedLevel.idStudentLevel}`, self.selectedLevel, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          this.show = false;
          this.$toasted.success("تم تعديل البيانات");
          this.$eidStudentLevel;
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
    saveData() {
      let self = this;

      if (self.acceptedName && self.discount) {
        let acceptedType = {
          acceptedName: self.acceptedName,
          discount: self.discount,
        };

        this.$http
          .post(
            `${this.$store.state.apiUrlRegistration}/addAcceptedType`,

            acceptedType,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            this.show = false;
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          })
          .catch((err) => {
            this.$toasted.error(" حدث خطأ في البيانات");
          });
      } else {
        this.$toasted.error("الرجاء ادخال ادخال جميع القيم");
      }
    },
    deleteStudentLevel(level) {
      console.log(level);
      this.deleteLevel = level;
      this.showDeleteLevel = true;
    },
    editLevel(level) {
      console.log(level);
      this.show = true;
      this.selectedLevel = level;
    },
    confirmDelete() {
      let self = this;
      this.$http
        .delete(`${this.$store.state.apiUrlRegistration}/studentLevel/${this.deleteLevel.idStudentLevel}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          this.show = false;
          this.showDeleteLevel = false;
          this.$toasted.success("تم حذف البيانات");
          this.$emit("deleted", true);
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
          this.show = false;
        });
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
