<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> تغيير كلمة المرور </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-text-field v-model="newPassword" type="password" outlined color="primary" label="كلمة المرور الجديدة" :rules="studentRequiredFormRules"></v-text-field>
          <v-text-field v-model="newPasswordCheck" type="password" outlined color="primary" label="اعادة كلمة المرور" :rules="studentRequiredFormRules"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" style="display: inline-block" color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-lock-reset </v-icon>
          تغيير كلمة المرور
        </v-btn>
      </template>
      <span>تغيير كلمة المرور</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    newPasswordCheck: "",
    newPassword: "",
  }),

  methods: {
    saveData() {
      let self = this;

      if (self.newPassword && self.newPasswordCheck) {
        if (self.newPasswordCheck == self.newPassword) {
          self.$http
            .put(
              `${this.$store.state.apiUrl}/resetPassword/${self.$store.state.userData.idEmployee}`,
              { password: self.newPassword },
              {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              }
            )
            .then((res) => {
              this.show = false;
              this.$toasted.success("تم تعديل البيانات");
              localStorage.removeItem("employee");
              location.reload();
            })
            .catch((resetError) => {
              this.$toasted.error(" حدث خطأ في تعديل البيانات");
            });
          console.log(self.$store.state.userData.idEmployee);
        } else {
          this.$toasted.error("كلمة المرور غير متطابقة");
        }
      } else {
        this.$toasted.error("الرجاء ادخال ادخال جميع القيم");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
