<template>
  <div style="margin-top: 30px">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="$store.state.userData.roleId != 61">
      <div style="margin-top: 30px"></div>
      <div style="margin: 0 50px" v-if="$store.state.statistics != null">
        <v-alert v-if="isDailyBoxesReceived()" dense outlined text type="error" border="left"> لم يتم قبض جميع الصناديق </v-alert>
        <v-alert v-else dense outlined text type="success" border="left"> تم قبض جميع الصناديق </v-alert>
      </div>

      <div style="margin-top: 30px"></div>
      <v-card elevation="6" class="mx-auto radius-1 mb-10" style="text-align: center; padding: 20px" max-width="344" color="primary">
        <h3 style="color: #ffffff">الارصدة الافتتاحية</h3>
      </v-card>
      <div v-if="$store.state.statistics != null">
        <v-row>
          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">الصندوق اقساط المراحل</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  $store.state.boxAmounts.length > 0
                    ? $store.state.boxAmounts
                        .find((box) => box.boxId == 1)
                        .amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                    : 0
                }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق هويات المراحل</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  $store.state.boxAmounts.length > 0
                    ? $store.state.boxAmounts
                        .find((box) => box.boxId == 2)
                        .amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                    : 0
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">الاستمارات</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  $store.state.boxAmounts.length > 0
                    ? $store.state.boxAmounts
                        .find((box) => box.boxId == 7)
                        .amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                    : 0
                }}
              </h4>
            </v-card>
          </v-col>

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">اقساط حجز مقعد</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  $store.state.boxAmounts.length > 0
                    ? $store.state.boxAmounts
                        .find((box) => box.boxId == 10)
                        .amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                    : 0
                }}
              </h4>
            </v-card>
          </v-col> -->

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">هويات حجز مقعد</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  $store.state.boxAmounts.length > 0
                    ? $store.state.boxAmounts
                        .find((box) => box.boxId == 9)
                        .amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                    : 0
                }}
              </h4>
            </v-card>
          </v-col> -->

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">خدمات جامعية للمراحل</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  $store.state.boxAmounts.length > 0
                    ? $store.state.boxAmounts
                        .find((box) => box.boxId == 11)
                        .amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                    : 0
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق اقساط الاول</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  $store.state.boxAmounts.length > 0
                    ? $store.state.boxAmounts
                        .find((box) => box.boxId == 15)
                        .amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                    : 0
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق هويات الاول</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  $store.state.boxAmounts.length > 0
                    ? $store.state.boxAmounts
                        .find((box) => box.boxId == 16)
                        .amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                    : 0
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق خدمات الاول</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  $store.state.boxAmounts.length > 0
                    ? $store.state.boxAmounts
                        .find((box) => box.boxId == 18)
                        .amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                    : 0
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق التأييدات</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  $store.state.boxAmounts.length > 0
                    ? $store.state.boxAmounts
                        .find((box) => box.boxId == 4)
                        .amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                    : 0
                }}
              </h4>
            </v-card>
          </v-col>

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق التدريب لطب الاسنان</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  $store.state.boxAmounts.length > 0
                    ? $store.state.boxAmounts
                        .find((box) => box.boxId == 21)
                        .amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                    : 0
                }}
              </h4>
            </v-card>
          </v-col> -->
        </v-row>

        <div style="margin-bottom: 30px"></div>

        <v-card elevation="6" class="mx-auto radius-1 mb-10" style="text-align: center; padding: 20px" max-width="344" color="primary">
          <h3 style="color: #ffffff">الارصدة</h3>
        </v-card>

        <v-row>
          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق اقساط المراحل</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalMain.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">الهويات للمراحل</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalIdentity.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">الاستمارات</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalCertificate.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">اقساط حجز مقعد</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalReservedBox.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">هويات حجز مقعد</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalIdentityReservedBox.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col> -->

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">خدمات جامعية للمراحل</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalFurniture.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق لجين</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.receiptLogen * 1 - $store.state.statistics.expensesLogen * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق التدريب الصيفي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.receiptTraining * 1 - $store.state.statistics.expensesTraining * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق المركز الطبي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.receiptLab * 1 - $store.state.statistics.expensesLab * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق اقساط المرحلة الاولى</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalMainLevelOneBox.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق هويات المرحلة الاولى</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalIdentityLevelOneBox.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق خدمات المرحلة الاولى</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalServiceLevelOneBox.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق التأييدات</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalConfirmBox.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق التدريب لطب الاسنان</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalDentilBox.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>
        </v-row>

        <div style="margin-bottom: 30px"></div>

        <v-card elevation="6" class="mx-auto radius-1 mb-10" style="text-align: center; padding: 20px" max-width="344" color="primary">
          <h3 style="color: #ffffff">الاحصائيات</h3>
        </v-card>

        <v-row>
          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">الطلبة المستمرين للمراحل</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ $store.state.statistics.studentLengthRestLevels }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">القسط الكلي للمراحل</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.amountAfterDiscountRestLevels - $store.state.statistics.repeatAmountRest - $store.state.statistics.discountAmountStudentsRest).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">القسط المدفوع للمراحل</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.paidRestLevels * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">المتبقي للمراحل</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  (
                    $store.state.statistics.amountAfterDiscountRestLevels -
                    $store.state.statistics.repeatAmountRest -
                    $store.state.statistics.discountAmountStudentsRest -
                    $store.state.statistics.paidRestLevels
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">جميع طلبة المرحلة الاولى</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ $store.state.statistics.studentLengthLevelOne }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">القسط الكلي للاول</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.amountAfterDiscountLevelOne - $store.state.statistics.repeatAmount - $store.state.statistics.discountAmountStudents).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">المدفوع للمرحلة الاولى</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.paidLevelOne * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">المتبقي للاول</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  (
                    $store.state.statistics.amountAfterDiscountLevelOne -
                    $store.state.statistics.repeatAmount -
                    $store.state.statistics.discountAmountStudents -
                    $store.state.statistics.paidLevelOne
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">مجموع المرحلة الاولى السابقين</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ $store.state.studentsContinue.length }}
              </h4>
            </v-card>
          </v-col> -->
        </v-row>

        <!-- <v-card elevation="6" class="mx-auto radius-1 mb-10" style="text-align: center; padding: 20px; margin: 10px 0px" max-width="344" color="primary">
          <h3 style="color: #ffffff">احصائيات المدفوعات للطلبة</h3>
        </v-card>

        <v-row v-if="!overlay">
          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين الدفعة الاولى للمرحلة الاولى السابقين</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelOneFirstPayment }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين الدفعة الثانية للمرحلة الاولى السابقين</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelOneSecondPayment }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين الدفعة الثالثة للمرحلة الاولى السابقين</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelOneThirdPayment }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين الدفعة الرابعة للمرحلة الاولى السابقين</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelOneFourthPayment }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين كامل المبلغ للمرحلة الاولى السابقين</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelOneFullPayment }}
              </h5>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="!overlay">
          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين الدفعة الاولى للمرحلة الاولى</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelOneNewFirstPayment }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين الدفعة الثانية للمرحلة الاولى</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelOneNewSecondPayment }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين الدفعة الثالثة للمرحلة الاولى</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelOneNewThirdPayment }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين الدفعة الرابعة للمرحلة الاولى</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelOneNewFourthPayment }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين كامل المبلغ للمرحلة الاولى</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelOneNewFullPayment }}
              </h5>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="!overlay">
          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين الدفعة الاولى للمراحل</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelsFirstPayment }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين الدفعة الثانية للمراحل</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelsSecondPayment }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين الدفعة الثالثة للمراحل</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelsThirdPayment }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين الدفعة الرابعة للمراحل</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelsFourthPayment }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">مسددين كامل المبلغ للمراحل</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{ paymentStatistics.levelsFullPayment }}
              </h5>
            </v-card>
          </v-col>
        </v-row> -->

        <div style="margin-top: 30px"></div>
        <v-card elevation="6" class="mx-auto radius-1 mb-10" style="text-align: center; padding: 20px" max-width="344" color="primary">
          <h3 style="color: #ffffff">صناديق الايرادات</h3>
        </v-card>
        <v-row>
          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">الصندوق الرئيسي</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.mainBox * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">الهويات مركزي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.identityBox * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صحة صدور مركزي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.correctBox * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">الوثائق والتأييدات مركزي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.certificateBox * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">سنوات سابقة مركزي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.previousYears * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>
        </v-row>

        <div style="margin-top: 30px"></div>
        <v-card elevation="6" class="mx-auto radius-1 mb-10" style="text-align: center; padding: 20px" max-width="344" color="primary">
          <h3 style="color: #ffffff">صناديق ايرادات يومي</h3>
        </v-card>

        <v-row>
          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق اقساط المراحل اليومي</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.restLevelsDailyPaid * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق اقساط الاول اليومي</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.levelOneDailyPaid * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق الهويات اليومي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.dailyIdentityBox * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق الوثائق والتأييدات يومي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.dailyCertificateBox * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق صحة الصدور يومي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.dailyCorrectBox * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق استمارات يومي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.dailyForm * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق الخدمات يومي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.dailyServicesBox * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>
        </v-row>

        <div style="margin-top: 30px"></div>
        <v-card elevation="6" class="mx-auto radius-1 mb-10" style="text-align: center; padding: 20px" max-width="344" color="primary">
          <h3 style="color: #ffffff">صندوق الكاشير اليومي</h3>
        </v-card>

        <v-row>
          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">علي مهدي</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.aliMahdi * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h5>
            </v-card>
          </v-col> -->

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">حسين صلاح مهدي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.hussienSalah * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">علي مازن صالح هادي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.aliMazen * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">موج واثق</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.moaj * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">ضمياء باسم</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.damyaa * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col> -->

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">ابراهيم رقيب</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.ibrahemRaqeeb * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">احمد خيري</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.ahmedK * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col> -->

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">مريم عباس</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.maryamAbbas * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col> -->

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">حنين رعد</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.hannenRaad * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col> -->

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">مريم علي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.maryamAli * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col> -->

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">وسام علي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.wisam * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">فداء ناصر</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.fedaa * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">زهراء علاء</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.zahraaAlaa * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col> -->

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">روان هشام</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.rawanHusham * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">همام رائد</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.humamRaad * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col> -->

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">سرمد علي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.sarmadAli * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col> -->

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">كرار عامر</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.kararAmer * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col> -->

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">فائق حسام</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.faekHusam * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">محمد ماهر</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.mohammedMaher * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col> -->

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">رفل خالد</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.rafal * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">اسماء سلام</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.laheeb * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">مريم حازم</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.maryamHazem * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">مروه نزهت</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.marwaNazhat * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col> -->

          <!-- <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">محمد ثامر</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.mohammedThamer * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col> -->

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">طيبة بهاء</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.teebaBahaa * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">بتول نصر</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.batolNaser * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">بشرى كامل</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.bushraKamel * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>
        </v-row>

        <div style="margin-top: 30px"></div>
        <v-card elevation="6" class="mx-auto radius-1 mb-10" style="text-align: center; padding: 20px" max-width="344" color="primary">
          <h3 style="color: #ffffff">صرفيات الصناديق</h3>
        </v-card>

        <v-row>
          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-account-group</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h5 class="warning-color" style="font-size: 1em; font-weight: bold">صرفيات الصندوق الرئيسي</h5>
                </v-col>
              </v-row>
              <h5 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.expensesAmount * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h5>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صرفيات صندوق الهويات</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.expensesIdentityAmount * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صرفيات الاستمارات</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.documentIdentityAmount * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صرفيات هويات حجز مقعد</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.expensesReservedIdentity * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صرفيات اقساط حجز مقعد</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  ($store.state.statistics.expensesInstallmentReserved * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div style="margin-bottom: 20px"></div>

      <!-- <v-row align="center" justify="center">
      <v-col cols="6" class="pa-4">
        <v-card class="ma-2 radius-1 pa-5" elevation="6">
          <SectionChart />
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-4">
        <v-card class="ma-2 radius-1 pa-5" elevation="6">
          <CategoryChart />
        </v-card>
      </v-col>
    </v-row> -->
    </div>
    <div v-if="$store.state.userData.roleId == 61">
      <EmployeeHomeStatistics :payments="payments" v-if="!overlay" />
    </div>
  </div>
</template>

<script>
import SectionChart from "./../components/SectionChart.vue";
import CategoryChart from "./../components/CategoryChart.vue";
import EmployeeHomeStatistics from "./../components/EmployeeHomeStatistics.vue";

export default {
  components: {
    SectionChart,
    CategoryChart,
    EmployeeHomeStatistics,
  },
  name: "Home",
  data: () => ({
    overlay: false,
    payments: [],
    advertisements: 0,
    sections: 0,
    categories: 0,
    totalIdentityReservedBox: 0,
    totalMain: 0,
    totalReservedBox: 0,
    totalIdentity: 0,
    totalCertificate: 0,
    paymentStatistics: null,
    totalFurniture: 0,
    totalDentilBox: 0,

    totalMainLevelOneBox: 0,
    totalIdentityLevelOneBox: 0,
    totalServiceLevelOneBox: 0,
    totalConfirmBox: 0,
  }),

  created() {
    if (this.$store.state.userData.roleId != 61) {
      this.initialization();
    } else {
      this.employeeStatistics();
    }
  },
  methods: {
    getDateXDaysAgo(numOfDays, date = new Date()) {
      const daysAgo = new Date(date.getTime());
      daysAgo.setDate(date.getDate() - numOfDays);
      console.log(daysAgo.toISOString().split("T")[0]);

      return daysAgo.toISOString().split("T")[0];
    },
    isDailyBoxesReceived() {
      let self = this;
      if (
        self.$store.state.statistics.dailyServicesBox * 1 == 0 &&
        self.$store.state.statistics.dailyCertificateBox * 1 == 0 &&
        self.$store.state.statistics.dailyForm * 1 == 0 &&
        self.$store.state.statistics.dailyCorrectBox * 1 == 0 &&
        self.$store.state.statistics.dailyIdentityBox * 1 == 0 &&
        self.$store.state.statistics.dailyInstallmentBox * 1 == 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    // ${self.$store.state.apiUrlFees}  http://localhost:4310/api
    initialization() {
      let self = this;

      self.overlay = true;
      let date = new Date();
      let fullDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

      // console.log(self.getDateXDaysAgo(1, new Date()));
      console.log(fullDate);
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/receiptsSearch?dates=${JSON.stringify([fullDate, fullDate])}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/expensesSearch?dates=${JSON.stringify([fullDate, fullDate])}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/getStatistics?tody=${fullDate}`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlFees}/boxAmountByDate/${self.getDateXDaysAgo(1, new Date())}`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/studentsContinue`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlFees}/getPaymentStatistics`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlFees}/paymentTypesNotInstallment`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/acceptedTypes`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/yearStudies`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/sections`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
        ])
        .then(
          self.$http.spread((receipts, expenses, statistics, boxAmounts, studentsContinue, paymentStatistics, paymentTypes, acceptedTypes, yearStudies, sections) => {
            console.log(statistics.data);

            self.$store.state.sections = sections.data;
            self.$store.state.years = yearStudies.data;
            self.$store.state.acceptedTypes = acceptedTypes.data;
            self.$store.state.paymentTypesNotInstallment = paymentTypes.data;
            self.paymentStatistics = paymentStatistics.data;
            self.$store.state.boxAmounts = boxAmounts.data;
            console.log(self.getDateXDaysAgo(1, new Date()));
            self.$store.state.statistics = statistics.data;
            self.$store.state.studentsContinue = studentsContinue.data;
            let expensesMainBox = expenses.data.filter(
              (expense) =>
                expense.boxId != 2 &&
                expense.boxId != 7 &&
                expense.boxId != 9 &&
                expense.boxId != 11 &&
                expense.boxId != 12 &&
                expense.boxId != 13 &&
                expense.boxId != 14 &&
                expense.boxId != 15 &&
                expense.boxId != 16 &&
                expense.boxId != 17 &&
                expense.boxId != 4 &&
                expense.boxId != 21 &&
                expense.boxId != 18
            );
            let expensesCertificateBox = expenses.data.filter((expense) => expense.boxId == 7);
            let expensesIdentityBox = expenses.data.filter((expense) => expense.boxId == 2);

            let expensesFurnitureBox = expenses.data.filter((expense) => expense.boxId == 11);
            let reservedBoxExpenses = expenses.data.filter((expense) => expense.boxId == 10);
            let reservedBoxReceipt = receipts.data.filter((receipt) => receipt.boxId == 10);

            let identityReservedBoxExpenses = expenses.data.filter((expense) => expense.boxId == 9);
            let identityReservedBoxReceipt = receipts.data.filter((receipt) => receipt.boxId == 9);

            let expensesMainLevelOne = expenses.data.filter((expense) => expense.boxId == 15);
            let expensesIdentityLevelOne = expenses.data.filter((expense) => expense.boxId == 16);
            let expensesServicesLevelOne = expenses.data.filter((expense) => expense.boxId == 18);
            let expensesConfirm = expenses.data.filter((expense) => expense.boxId == 4);

            let receiptMainLevelOne = receipts.data.filter((receipt) => receipt.boxId == 15);
            let receiptIdentityLevelOne = receipts.data.filter((receipt) => receipt.boxId == 16);
            let receiptServicesLevelOne = receipts.data.filter((receipt) => receipt.boxId == 18);
            let receiptConfirm = receipts.data.filter((receipt) => receipt.boxId == 4);

            let receiptDentil = receipts.data.filter((receipt) => receipt.boxId == 21);
            let expensesDentil = expenses.data.filter((expense) => expense.boxId == 21);

            let totalDentilBox =
              receiptDentil.reduce((n, { amount }) => n + amount, 0) +
              (self.$store.state.boxAmounts.find((box) => box.boxId == 21) ? self.$store.state.boxAmounts.find((box) => box.boxId == 21).amount : 0) -
              expensesDentil.reduce((n, { amount }) => n + amount, 0);

            let receiptMainBox = receipts.data.filter(
              (receipt) =>
                receipt.boxId != 2 &&
                receipt.boxId != 7 &&
                receipt.boxId != 9 &&
                receipt.boxId != 11 &&
                receipt.boxId != 12 &&
                receipt.boxId != 13 &&
                receipt.boxId != 14 &&
                receipt.boxId != 15 &&
                receipt.boxId != 16 &&
                receipt.boxId != 17 &&
                receipt.boxId != 4 &&
                receipt.boxId != 21 &&
                receipt.boxId != 18
            );
            let receiptCertificateBox = receipts.data.filter((receipt) => receipt.boxId == 7);
            let receiptFurnitureBox = receipts.data.filter((receipt) => receipt.boxId == 11);
            let receiptIdentityBox = receipts.data.filter((receipt) => receipt.boxId == 2);

            let receiptLogenBox = receipts.data.filter((receipt) => receipt.boxId == 12);
            let expensesLogenBox = expenses.data.filter((expense) => expense.boxId == 12);
            console.log(receiptLogenBox);
            if (self.$store.state.boxAmounts.length > 0) {
              let totalMainLevelOneBoxReserved =
                receiptMainLevelOne.reduce((n, { amount }) => n + amount, 0) +
                (self.$store.state.boxAmounts.find((box) => box.boxId == 15) ? self.$store.state.boxAmounts.find((box) => box.boxId == 15).amount : 0) -
                expensesMainLevelOne.reduce((n, { amount }) => n + amount, 0);

              let totalIdentityLevelOneBoxReserved =
                receiptIdentityLevelOne.reduce((n, { amount }) => n + amount, 0) +
                (self.$store.state.boxAmounts.find((box) => box.boxId == 16) ? self.$store.state.boxAmounts.find((box) => box.boxId == 16).amount : 0) -
                expensesIdentityLevelOne.reduce((n, { amount }) => n + amount, 0);

              let totalServicesLevelOneBoxReserved =
                receiptServicesLevelOne.reduce((n, { amount }) => n + amount, 0) +
                (self.$store.state.boxAmounts.find((box) => box.boxId == 18) ? self.$store.state.boxAmounts.find((box) => box.boxId == 18).amount : 0) -
                expensesServicesLevelOne.reduce((n, { amount }) => n + amount, 0);

              let totalConfirmBoxReserved =
                receiptConfirm.reduce((n, { amount }) => n + amount, 0) +
                (self.$store.state.boxAmounts.find((box) => box.boxId == 4) ? self.$store.state.boxAmounts.find((box) => box.boxId == 4).amount : 0) -
                expensesConfirm.reduce((n, { amount }) => n + amount, 0);

              console.log(totalConfirmBoxReserved);

              let totalMain =
                receiptMainBox.reduce((n, { amount }) => n + amount, 0) + self.$store.state.boxAmounts.find((box) => box.boxId == 1).amount - expensesMainBox.reduce((n, { amount }) => n + amount, 0);
              let logenBox = receiptLogenBox.reduce((n, { amount }) => n + amount, 0) - expensesLogenBox.reduce((n, { amount }) => n + amount, 0);
              console.log(logenBox);
              let totalIdentity =
                receiptIdentityBox.reduce((n, { amount }) => n + amount, 0) +
                self.$store.state.boxAmounts.find((box) => box.boxId == 2).amount -
                expensesIdentityBox.reduce((n, { amount }) => n + amount, 0);
              let totalCertificate =
                receiptCertificateBox.reduce((n, { amount }) => n + amount, 0) +
                self.$store.state.boxAmounts.find((box) => box.boxId == 7).amount -
                expensesCertificateBox.reduce((n, { amount }) => n + amount, 0);
              let totalReservedBox =
                reservedBoxReceipt.reduce((n, { amount }) => n + amount, 0) +
                self.$store.state.boxAmounts.find((box) => box.boxId == 10).amount -
                reservedBoxExpenses.reduce((n, { amount }) => n + amount, 0);

              let totalIdentityReservedBox =
                identityReservedBoxReceipt.reduce((n, { amount }) => n + amount, 0) +
                self.$store.state.boxAmounts.find((box) => box.boxId == 9).amount -
                identityReservedBoxExpenses.reduce((n, { amount }) => n + amount, 0);
              console.log(self.totalReservedBox);

              let totalFurniture =
                receiptFurnitureBox.reduce((n, { amount }) => n + amount, 0) +
                self.$store.state.boxAmounts.find((box) => box.boxId == 11).amount -
                expensesFurnitureBox.reduce((n, { amount }) => n + amount, 0);

              self.totalReservedBox = totalReservedBox;
              self.totalIdentityReservedBox = totalIdentityReservedBox;
              self.totalCertificate = totalCertificate;
              self.totalIdentity = totalIdentity;
              self.totalMain = totalMain;
              self.totalFurniture = totalFurniture;
              self.totalDentilBox = totalDentilBox;
              self.totalMainLevelOneBox = totalMainLevelOneBoxReserved;
              self.totalIdentityLevelOneBox = totalIdentityLevelOneBoxReserved;
              self.totalServiceLevelOneBox = totalServicesLevelOneBoxReserved;
              self.totalConfirmBox = totalConfirmBoxReserved;
            }

            self.overlay = false;
          })
        )
        .catch((e) => {
          console.log(e);
          self.overlay = false;
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
    employeeStatistics() {
      let self = this;
      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/paymentTypesNotInstallment`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/acceptedTypes`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/yearStudies`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/sections`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
        ])
        .then(
          self.$http.spread((paymentTypes, acceptedTypes, yearStudies, sections) => {
            self.$store.state.sections = sections.data;
            self.$store.state.years = yearStudies.data;
            self.$store.state.acceptedTypes = acceptedTypes.data;
            self.$store.state.paymentTypesNotInstallment = paymentTypes.data;
          })
        );

      self.$http
        .get(`${self.$store.state.apiUrlFees}/studentPaymentEmployee/${self.$store.state.userData.idEmployee}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.payments = res.data;
          self.overlay = false;
          console.log(res.data);
        })
        .catch((e) => {
          self.overlay = false;
          console.log(e);
        });
    },
  },
};
</script>
