<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>صفحة المصروفات</h3>
          </v-col>
          <v-col cols="2" align="left">
            <AddExpenses style="display: inline-block" :expensesTypes="expensesTypes" :boxes="boxes" @reloadTask="initialData" v-if="!overlay && $store.state.userData.idEmployee != 614" />
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="expenses" :items-per-page="15" :search="search" item-key="idReceipt" class="table-content">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <p style="padding: 0 3px">
              {{
                item.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </p>
          </template>

          <template v-slot:[`item.isMark`]="{ item }">
            <v-btn icon v-if="item.isMark != 0" @click="changeIsMark(item)">
              <v-icon color="success">mdi-check-circle</v-icon>
            </v-btn>

            <v-btn icon v-else @click="changeIsMark(item)">
              <v-icon color="#9f9f9f">mdi-adjust</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <EditExpenses v-if="$store.state.userData.idEmployee != 614" :expensesTypes="expensesTypes" :expense="item" style="display: inline-block" @reloadTask="initialData" />

            <v-btn icon @click="printVoucher(item)">
              <v-icon color="primary"> mdi-printer </v-icon>
            </v-btn>
            <ExpensesImages :expenses="item" style="display: inline-block" @reloadTask="initialData()" />
            <DeleteDialog
              v-if="$store.state.userData.idEmployee != 614"
              :dataToDelete="item"
              :url="`${$store.state.apiUrlFees}/expenses/${item.idExpenses}`"
              style="display: inline-block"
              @reloadTask="initialData()"
            />
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <canvas id="barcode"></canvas>
  </div>
</template>

<script>
import AddExpenses from "../components/AddExpenses.vue";
import EditExpenses from "../components/EditExpenses.vue";
import DeleteDialog from "../components/DeleteDialog.vue";
import ExpensesImages from "../components/ExpensesImages.vue";
import { jsPDF } from "jspdf";
var JsBarcode = require("jsbarcode");
import n2wordsAR from "n2words/lib/i18n/AR.mjs";
export default {
  components: {
    AddExpenses,
    EditExpenses,
    DeleteDialog,
    ExpensesImages,
  },
  data: () => ({
    overlay: false,
    search: "",
    expenses: [],
    boxes: [],
    expensesTypes: [],
    headers: [
      { text: "التسلسل", value: "index", width: 100 },
      { text: "رقم الوصل", value: "idExpenses" },
      { text: "تاريخ الوصل", value: "expensesDateFormat" },
      { text: "نوع السند", value: "typeName" },
      { text: "الصندوق", value: "boxName" },
      { text: "المبلغ", value: "amount" },
      { text: "الملاحظات", value: "notes", width: 600 },
      { text: "بواسطة", value: "employeeName" },
      { text: "الجهة المستفيدة", value: "benefit" },
      { text: "راجع", value: "isMark" },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    itemRowBackground(item) {
      return item.isMark == 1 ? "style-1" : "style-2";
    },
    initialData() {
      let self = this;
      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/allExpensesByYearId/75`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/boxes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/expensesTypes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((allExpenses, boxes, expensesTypes) => {
            console.log(allExpenses);
            self.expenses = allExpenses.data.reverse();
            self.boxes = boxes.data;
            self.expensesTypes = expensesTypes.data;
            self.overlay = false;
          })
        )
        .catch((e) => {
          console.log(e);
          self.overlay = false;
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },

    changeIsMark(item) {
      console.log(item);
      this.$http
        .put(
          `${this.$store.state.apiUrlFees}/expensesIsMark/${item.idExpenses}`,
          {
            isMark: item.isMark == 0 ? 1 : 0,
          },
          {
            headers: { Authorization: `Bearer ${this.$store.state.user}` },
          }
        )
        .then((res) => {
          this.show = false;
          this.$toasted.success("تم تعديل البيانات");
          this.initialData();
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
    printVoucher(voucher) {
      let self = this;
      JsBarcode("#barcode", `${voucher.idReceipt}`, {
        width: 3,
        height: 30,
        displayValue: false,
      });
      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      let image = require("../assets/logo.png");

      // Open the print window
      const WinPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");
      console.log(window);
      let numberWriting = n2wordsAR(voucher.amount, { lang: "ar" });
      let barcodeCanvas = document.getElementById("barcode");
      console.log(barcodeCanvas);
      WinPrint.document.write(`<!DOCTYPE html>
      <!DOCTYPE html>
        <html lang="ar">
        ${stylesHtml}

          <style>
          body ,html{ margin:0px !important}
          body{
            border:1px solid #eee !important;
          }
          @media print {
              @page {
                size: A5 landscape !important;
                margin: 0cm;
              }
              @page :left {
                margin: 0cm;
              }

              @page :right {
                margin: 0cm;
              }
              .not-printable {
                display: none !important;
              }
              body {
                -webkit-print-color-adjust: exact !important;
                height: 100% !important;
                width: 100% !important;
                padding: 0px !important;
                margin: 0px !important;
                color: #000;
                background-color: #fff !important;
                page-break-before: avoid;
                page-break-before: avoid;
              }
              .adjust-main {
                padding: 0px !important;
              }
              header nav,
              footer {
                display: none;
              }
              h3 {
                font-size: 11px !important;
              }
              h5 {
                font-size: 9px !important;
              }

              .center-print {
                background: #ffffff !important;
              }

              .image-print {
                width: 100px !important;
                height: 100px !important;
                text-align: center !important;
                margin: auto !important;
              }
              span {
                font-size: 10px !important;
                font-weight: bolder !important;
              }
              .center-print {
                text-align: center !important;
              }
            }
            img {
              padding: 5px;
            }
            .image-print {
              width: 80px !important;
              height: 80px !important;
              text-align: center !important;
              margin: auto !important;
            }
            span {
              font-size: 10px !important;
              font-weight: bolder !important;
            }

            .center-print {
              text-align: center !important;
              background: #ffffff !important;
            }
            .print-padding {
              padding: 5px !important;
            } 
          </style>
          <body >
            <div data-v-4a284cfa="" class="center-print" style="border: 2px solid rgb(37, 37, 37); margin-top: 5px;height:100vh">
              <div data-v-4a284cfa="">
                <div data-v-4a284cfa="" class="row print-padding">
                  <div data-v-4a284cfa="" class="col col-4">
                    <h3 data-v-4a284cfa="">رقم الوصل : ${voucher.idExpenses}</h3>
                    <h3 data-v-4a284cfa="">تاريخ الوصل : ${voucher.expensesDateFormat}</h3>
                  </div>
                  <div data-v-4a284cfa="" class="col col-4">
                    <div data-v-4a284cfa="" class="v-image v-responsive my-3 theme--light" style="height: 90px">
                      <div class="v-responsive__sizer" style="padding-bottom: 99.5851%"></div>
                      <div class="v-image__image v-image__image--contain" style="background-image: url('${image}'); background-position: center center"></div>
                      <div class="v-responsive__content" style="width: 241px"></div>
                    </div>
                  </div>
               
                </div>
                <div data-v-4a284cfa="">
                  <div data-v-4a284cfa="" class="row">
                    <div data-v-4a284cfa="" class="col col-4"></div>
                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 data-v-4a284cfa="">جامعة التراث / الحسابات</h3>
                      <h3>سند دفع</h3>
                      <div data-v-4a284cfa="">
                        <img src="${barcodeCanvas.toDataURL()}"/>
                        <div style="display: none">Show this if the rendering fails.</div>
                      </div>
                    </div>
                    <div data-v-4a284cfa="" class="col col-4"></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3>اسم الحساب</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">${voucher.typeName}</h3></div>
                    <div data-v-4a284cfa="" class="col col-4"></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                 
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">مدين</h3></div>
                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 data-v-4a284cfa="" style="display: inline-block; margin-left: 10px">${voucher.amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "IQD",
                      })}</h3>
                      <h3 data-v-4a284cfa="" style="display: inline-block ; text-align:center">${numberWriting} فقط لاغير </h3>
                    </div>
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">Amount Of</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row print-padding">
                    <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">البيان</h3></div>
                    <div data-v-4a284cfa="" class="col col-8"><h3 data-v-4a284cfa="">${voucher.notes}</h3></div>
                  </div>
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div data-v-4a284cfa="" class="row" style="padding-top:30px">
                    <div data-v-4a284cfa="" class="col col-3">
                      <h3>اسم المستلم وتوقيعه</h3>
                      </div>
                    <div data-v-4a284cfa="" class="col col-3"><h3>المدقق</h3></div>
                    <div data-v-4a284cfa="" class="col col-3" style="padding-bottom: 80px">
                      <h3 data-v-4a284cfa="">امين الصندوق</h3>
                    </div>
                    <div data-v-4a284cfa="" class="col col-3"><h3>مدير الحسابات</h3></div>
                  </div>
                </div>
              </div>
            </div>
          </body>
        </html>
        `);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
    printVoucher2(voucher) {
      console.log(voucher);
      this.$router.push({ name: "PrintExpenses", params: { invoice: JSON.stringify(voucher) } });
      // const doc = new jsPDF("l", "mm", [148, 210]);

      // doc.addFileToVFS("Amiri-normal.ttf", ArabicFont);
      // doc.addFont("Amiri-normal.ttf", "Amiri", "normal");
      // doc.setFont("Amiri");

      // var img = new Image();
      // img.src = require("../assets/logo.png");

      // doc.setFontSize(10);
      // if (voucher.paymentTypeId < 10) {
      //   doc.text(
      //     `${this.sectionCost.toLocaleString("en-US", {
      //       style: "currency",
      //       currency: "IQD",
      //     })} :  اجور الدراسة`,
      //     10,
      //     20
      //   );

      //   doc.text(`نسبة التحصيل الاجمالية :  % ${100 - this.$store.state.studentDetails.discount}`, 10, 25);
      // }

      // doc.text(`${voucher.idExpenses} : رقم الوصل`, 200, 20, { align: "right" });
      // doc.text(`تاريخ الوصل :  ${voucher.expensesDateFormat}`, 200, 25, { align: "right" });
      // doc.addImage(img, "png", 97, 15, 20, 20);
      // doc.text("جامعة التراث / الحسابات", 92, 38);
      // doc.text("سند دفع", 102, 42);

      // JsBarcode("#barcode", `${voucher.idReceipt}`, {
      //   width: 2,
      //   height: 30,
      //   displayValue: false,
      // });
      // let barcodeCanvas = document.getElementById("barcode");

      // doc.addImage(barcodeCanvas.toDataURL(), "png", 97, 45, 20, 8);

      // doc.line(10, 66, 200, 66);
      // doc.text("اسم الحساب", 200, 63, { align: "right" });
      // doc.text(`${voucher.typeName}`, 100, 63);

      // doc.text("مدين", 200, 72, { align: "right" });

      // doc.text(
      //   `${voucher.amount.toLocaleString("en-US", {
      //     style: "currency",
      //     currency: "IQD",
      //   })}`,
      //   140,
      //   72,
      //   { align: "right" }
      // );
      // doc.text(n2wordsAR(voucher.amount, { lang: "ar" }), 100, 72, { align: "right" });

      // doc.line(10, 78, 200, 78);
      // doc.text("البيان", 200, 84, { align: "right" });
      // doc.text(`تسديد ${voucher.notes}`, 140, 84, { align: "right" });

      // doc.line(10, 89, 200, 89);

      // doc.text("اسم المستلم وتوقيعه", 190, 115, { align: "right" });
      // doc.text("المدقق", 140, 115, { align: "right" });
      // doc.text("امين الصندوق", 90, 115, { align: "right" });
      // doc.text("مدير الحسابات", 40, 115, { align: "right" });

      // doc.rect(5, 5, 200, 135);

      // //========================================================================================END OF SECTION ONE =========================================================
      // barcodeCanvas.style.display = "none";
      // doc.autoPrint({ variant: "non-conform" });
      // const blob = doc.output("bloburl");
      // window.open(blob);
      // // doc.save("a4.pdf");
    },
  },
};
</script>

<style lang="scss"></style>
