<template>
  <div>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>صفحة التقارير</h3>
          </v-col>
          <v-col cols="2" align="left">
            <SearchPaidStudent style="display: inline-block" @clicked="onSearch" :paymentTypes="paymentTypes" />

            <v-btn v-if="reportData.length > 0" style="display: inline-block" class="mx-2" @click="exportExcel()" fab small color="primary">
              <v-icon> mdi-microsoft-excel </v-icon>
            </v-btn>

            <v-btn v-if="reportData.length > 0" style="display: inline-block" class="mx-2" @click="printReport()" fab small color="primary">
              <v-icon> mdi-printer </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="container" fluid>
      <v-card>
        <h3 v-if="reportData.length == 0" style="text-align: center; padding: 10px">{{ dataMessage }}</h3>
      </v-card>
    </v-container>

    <v-container class="container not-printable" fluid v-if="reportData.length > 0">
      <table style="width: 100%" class="table-data" border="1">
        <tr>
          <th>التسلسل</th>
          <th>اسم الطالب</th>
          <th>المرحلة</th>
          <th>القسم</th>
          <th>نوع الدراسة</th>
          <th>قناة القبول</th>
          <th>تسديد</th>
          <th>المبلغ المتبقي</th>
        </tr>
        <tr v-for="(item, index) in reportData" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.studentName }}</td>
          <td>{{ item.level | setLevel }}</td>
          <td>{{ item.sectionName }}</td>
          <td>{{ item.studyType == 1 ? "صباحي" : "مسائي" }}</td>
          <td>{{ item.acceptedName }}</td>
          <td>{{ item.isPaid ? "مسدد" : "غير مسدد" }}</td>

          <td>
            {{
              item.remainingAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </td>
        </tr>
      </table>
    </v-container>
  </div>
</template>

<script>
import SearchPaidStudent from "../components/SearchPaidStudent.vue";
const Excel = require("exceljs");
export default {
  components: {
    SearchPaidStudent,
  },
  data: () => ({
    search: "",
    selected: [],
    reportData: [],
    paymentTypeId: "",
    dataMessage: "يرجى البحث من اعلى اليسار",
    headers: [],
    paymentTypes: [],
    showExport: false,
    typeId: 0,
    voucherIndex: 0,

    dailyReport: "",
  }),
  created() {
    this.initialization();
  },
  methods: {
    initialization() {
      let self = this;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/paymentTypesForContinue`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((paymentTypes) => {
            self.paymentTypes = paymentTypes.data.filter((pay) => pay.idPaymentType < 10);
          })
        )
        .catch((e) => console.log(e));
    },

    onSearch(value) {
      let self = this;
      console.log(value);
      if (value.students.length > 0) {
        console.log(value);
        self.paymentTypeId = value.paymentTypeId;
        self.reportData = value.students.reverse();
      } else {
        self.dataMessage = "لايوجد بيانات";
      }
    },
    exportExcel() {
      let self = this;
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet("student paid");
      worksheet.views = [{ rightToLeft: true }];

      worksheet.columns = [
        { header: "اسم الطالب", key: "studentName", width: 50 },
        // { header: "الرقم الجامعي", key: "collegeNumber", width: 50 },
        // { header: "ايميل الطالب", key: "mail", width: 50 },
        // { header: "الجنس", key: "gender", width: 50 },
        // { header: "المواليد", key: "dob", width: 50 },
        { header: "المرحلة", key: "level", width: 30 },
        { header: "القسم", key: "sectionName", width: 30 },
        { header: "نوع الدراسة", key: "studyType", width: 20 },
        { header: "قناة القبول", key: "acceptedName", width: 50 },
        { header: "تسديد", key: "isPaid", width: 50 },
        { header: "نسبة التسديد", key: "paidPercentage", width: 50 },
        { header: "المبلغ المدفوع", key: "alreadyPaid", width: 50 },
      ];

      self.reportData.forEach((student) => {
        worksheet.addRow({
          studentName: student.studentName,
          // collegeNumber: student.collegeNumber,
          // mail: student.mail,
          // gender: student.gender,
          // dob: student.dob,
          level: self.$options.filters.setLevel(student.level),
          sectionName: student.sectionName,
          // studyType: "صباحي",
          studyType: student.studyType == 1 ? "صباحي" : "مسائي",
          acceptedName: student.acceptedName,
          isPaid: student.isPaid ? "مسدد" : "غير مسدد",
          paidPercentage: `${student.paidPercentage + student.discount}%`,
          alreadyPaid: student.alreadyPaid,
        });
      });

      worksheet.eachRow(function (row, rowNumber) {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = {
          type: "pattern",
          pattern: "darkTrellis",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `expensesReport.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
    getSheetName(index) {
      switch (index) {
        case 0:
          return "المقبوضات الخارجية";
        case 1:
          return "الاقساط";
        case 2:
          return "الهويات";
        case 3:
          return "الاستمارات";
        case 4:
          return "صحة الصدور";
        case 5:
          return "السنوات السابقة";
        case 6:
          return "التأييدات والوثائق";
      }
    },
    printReport() {
      let self = this;
      const WinPrint = window.open("", "", "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0");
      WinPrint.document.write(`<!DOCTYPE html>
        <!DOCTYPE html>

        <html lang="ar">
        <style>

        @media print {
          @page { margin: 0.5cm !important; }
          body { margin: 0.5cm !important; }
          .pagebreak {
            clear: both;page-break-after: always;
          }
        }
          body {
            direction : rtl !important;
          }
          table {
            width : 98% !important;
          }
          tr,tr td,th{
            text-align : center !important;
            font-size : 12px !important;
            padding : 5px !important;
          }
        </style>
        <body>

              ${self.printTableData()}
        </body>`);

      self.voucherIndex = 0;
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
    sliceArrayPrint(arrayOfData) {
      let begin = 0;
      let end = 28;
      let newArray = [];
      let numberOfIteration = Math.ceil(arrayOfData.length / 28);
      console.log(Math.ceil(arrayOfData.length / 28));

      for (let i = 0; i < numberOfIteration; i++) {
        newArray.push(arrayOfData.slice(begin, end * (i + 1)));
        begin += 28;
      }
      return newArray;
    },

    printTableData() {
      let self = this;
      let tableData = "";
      let receiptData = self.reportData;
      let dataPrint = self.sliceArrayPrint(receiptData.reverse());

      for (let i = 0; i < dataPrint.length; i++) {
        tableData += `
        <h3 style="text-align:center">${self.reportData[0].sectionName}</h3>
        <table border='1' style="padding:3px;margin: auto !important;">
            <tr>
                <th>التسلسل</th>
                <th>اسم الطالب</th>
                <th>المرحلة</th>
                <th>القسم</th>
                <th>نوع الدراسة</th>
                <th>قناة القبول</th>
                <th>تسديد</th>
                <th>نسبة التسديد</th>
                <th>المبلغ المدفوع</th>
              </tr>
              ${self.rowTable(dataPrint[i])}
          </table>
            ${i == dataPrint.length - 1 ? "" : '<div style=" clear: both;page-break-after: always;"></div>'}

          `;
      }
      return tableData;
    },
    rowTable(arrayOfData) {
      let self = this;
      let tdData = "";
      for (let j = 0; j < arrayOfData.length; j++) {
        console.log(self.voucherIndex);
        self.voucherIndex += 1;
        tdData += `
              <tr>
                <td>${self.voucherIndex}</td>
                <td>${arrayOfData[j].studentName}</td>
                <td>${self.$options.filters.setLevel(arrayOfData[j].level)}</td>
                <td>${arrayOfData[j].sectionName}</td>
                <td> ${arrayOfData[j].studyType == 1 ? "صباحي" : "مسائي"}</td>
                <td>${arrayOfData[j].acceptedName}</td>
                <td>${arrayOfData[j].isPaid ? "مسدد" : "غير مسدد"}</td>
                <td>${arrayOfData[j].paidPercentage + arrayOfData[j].discount}٪</td>
                <td>
                  ${arrayOfData[j].alreadyPaid.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })}
                </td>
              </tr>

          `;
      }
      return tdData;
    },
  },

  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
tr,
tr td {
  text-align: center !important;
}
</style>
