<template>
  <div>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>صفحة التقارير</h3>
          </v-col>
          <v-col cols="2" align="left">
            <SearchStudentDiscountReport style="display: inline-block" @clicked="onSearch" />

            <v-btn class="mx-2" color="primary" fab small @click="exportExcel()" style="display: inline-block" v-if="reportData.length > 0">
              <v-icon color="secondary"> mdi-microsoft-excel </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card>
        <v-container class="container" fluid>
          <div></div>
          <table style="width: 100%" class="table-data" border="1">
            <tr>
              <th>التسلسل</th>
              <th>اسم الطالب</th>
              <th>قناة القبول</th>
              <th>القسم</th>
              <th>القسط</th>
              <th>نسبة التخفيض</th>
              <th>المبلغ المخفض</th>
              <th>القسط بعد التخفيض</th>
              <th>المرحلة</th>
            </tr>
            <tr v-for="(item, index) in reportData" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.studentName }}</td>
              <td>{{ item.acceptedName }}</td>
              <td>{{ item.sectionName }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.discount }}</td>
              <td>{{ item.amount - item.discountAmount }}</td>
              <td>{{ item.discountAmount }}</td>
              <td>{{ item.level | setLevel }}</td>
            </tr>
          </table>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
const Excel = require("exceljs");
import SearchStudentDiscountReport from "./../components/SearchStudentDiscountReport.vue";
export default {
  components: {
    SearchStudentDiscountReport,
  },
  data() {
    return {
      dataMessage: "لانشاء تقرير يرجى البحث من اعلى اليسار",
      typeId: 0,
      showExport: false,
      reportData: [],
    };
  },
  methods: {
    onSearch(value) {
      let self = this;
      self.reportData = [];
      if (value.length > 0) {
        self.showExport = true;
        self.reportData = value;
        console.log(value);
      } else {
        self.showExport = false;
        self.dataMessage = "لايوجد بيانات";
      }
    },
    exportExcel() {
      let self = this;
      const workbook = new Excel.Workbook();

      const worksheet = workbook.addWorksheet("تقرير التخفيضات");
      worksheet.views = [{ rightToLeft: true }];

      worksheet.columns = [
        { header: "اسم الطالب", key: "studentName", width: 50 },
        { header: "قناة القبول", key: "acceptedName", width: 50 },
        { header: "القسم", key: "sectionName", width: 50 },
        { header: "القسط", key: "amount", width: 50 },
        { header: "نسبة التخفيض", key: "discount", width: 50 },
        { header: "المبلغ المخفض", key: "afterAmount", width: 50 },
        { header: "القسط بعد التخفيض", key: "discountAmount", width: 50 },
        { header: "المرحلة", key: "level", width: 50 },
      ];

      self.reportData.forEach((item) => {
        worksheet.addRow({
          studentName: item.studentName,
          acceptedName: item.acceptedName,
          sectionName: item.sectionName,
          amount: item.amount,
          discount: item.discount,
          afterAmount: item.amount - item.discountAmount,
          discountAmount: item.discountAmount,
          level: this.$options.filters.setLevel(item.level),
        });
      });

      worksheet.eachRow(function (row, rowNumber) {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = {
          type: "pattern",
          pattern: "darkTrellis",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `discountReport.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
tr,
tr td {
  text-align: center !important;
  padding: 5px !important;
}
</style>
