<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="searchDialog" persistent max-width="700px" width="700px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>بحث في الطلبة</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 20px"></div>

          <v-autocomplete v-model="sectionId" :items="$store.state.sections" item-text="sectionName" item-value="idSection" outlined label="القسم"></v-autocomplete>

          <v-autocomplete v-model="level" :items="levels" item-text="levelName" item-value="level" outlined label="المرحلة"></v-autocomplete>

          <v-autocomplete v-model="paymentTypeId" :items="paymentTypes" item-text="typeName" item-value="idPaymentType" outlined label="عن"></v-autocomplete>

          <v-autocomplete v-model="typeOfPayId" :items="typeOfPay" item-text="name" item-value="id" outlined label="النوع"></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="searchStudentPaid" v-on:keyup.enter="searchStudentPaid"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="searchDialog = true" v-bind="attrs" v-on="on">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </template>
      <span>بحث</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    paymentTypes: Array,
  },
  data: () => ({
    searchDialog: false,
    sectionId: "",
    typeOfPayId: "",
    paymentTypeId: "",
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    studentName: "",
    collegeNumber: "",
    overlay: false,
    typeOfPay: [
      { name: "المسددين", id: 1 },
      { name: "غير مسددين", id: 2 },
      { name: "الجميع", id: 3 },
    ],
    levels: [
      { levelName: "المرحلة الاولى", level: 1 },
      { levelName: "المرحلة الثانية", level: 2 },
      { levelName: "المرحلة الثالثة", level: 3 },
      { levelName: "المرحلة الرابعة", level: 4 },
      { levelName: "المرحلة الخامسة", level: 5 },
      { levelName: "جميع المراحل", level: 10 },
    ],
    level: "",
    allSectionInstallments: [],
  }),
  created() {},
  methods: {
    searchStudentPaid() {
      let self = this;
      let query = "studentStatusId = 1";

      if (self.sectionId) {
        query += `&sectionId=${self.sectionId}`;
      }

      if (self.level) {
        query += `&level=${self.level}`;
      }

      if (self.paymentTypeId) {
        query += `&paymentType=${self.paymentTypeId}`;
      }

      self.$http
        .get(`${self.$store.state.apiUrlRegistration}/v2/paidStudentsIdentity?${query}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.overlay = false;
          self.searchDialog = false;
          let studentPay = res.data[0].map((student) => {
            console.log(res.data[1].filter((pay) => pay.collegeNumber == student.collegeNumber));
            return {
              studentName: student.studentName,
              level: student.level,
              idStudent: student.idStudent,
              acceptedName: student.acceptedName,
              studyType: student.studyType,
              sectionName: student.sectionName,
              isPaid: self.studentPayment(res.data[1].filter((pay) => pay.collegeNumber == student.collegeNumber)) == "مسدد" ? true : false,
              discount: student.discount,
              collegeNumber: student.collegeNumber,
              mail: student.mail,
              dob: student.dob,
              gender: student.gender,
            };
          });
          console.log(studentPay);
          let data = {
            paymentTypeId: self.paymentTypeId,
            students: self.typeOfPayId == 1 ? studentPay.filter((student) => student.isPaid == true) : self.typeOfPayId == 2 ? studentPay.filter((student) => student.isPaid == false) : studentPay,
          };
          this.$emit("clicked", data);
        })
        .catch((err) => {
          console.log(err);
          self.overlay = false;
        });
    },
    searchData() {
      let self = this;
      self.overlay = true;
      let query = "studentStatusId = 1";

      if (self.sectionId) {
        query += `&sectionId=${self.sectionId}`;
      }

      if (self.level) {
        query += `&level=${self.level}`;
      }
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/filterSectionInstallments?sectionId=${self.sectionId}&studyYearId=75`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/paidStudents?${query}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/v2/paidStudents?${query}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((sectionInstallments, students, studentV2) => {
            console.log(studentV2);

            self.allSectionInstallments = sectionInstallments.data;

            self.overlay = false;
            let studentPay = students.data.map((student) => {
              return {
                studentName: student.studentName,
                level: student.level,
                idStudent: student.idStudent,
                acceptedName: student.acceptedName,
                studyType: student.studyType,
                sectionName: student.sectionName,
                isPaid: self.studentPayment(student.payments) == "مسدد" ? true : false,
                discount: student.discount,
                collegeNumber: student.collegeNumber,
                mail: student.mail,
                dob: student.dob,
                gender: student.gender,
              };
            });
            console.log(students.data);
            let data = {
              paymentTypeId: self.paymentTypeId,
              students: self.typeOfPayId == 1 ? studentPay.filter((student) => student.isPaid == true) : self.typeOfPayId == 2 ? studentPay.filter((student) => student.isPaid == false) : studentPay,
            };
            this.$emit("clicked", data);
          })
        )
        .catch((e) => {
          console.log(e);
          self.overlay = false;
        });

      // self.$http
      //   .get(`${self.$store.state.apiUrlRegistration}/paidStudents?${query}`, {
      //     headers: { Authorization: `Bearer ${self.$store.state.user}` },
      //   })
      //   .then((res) => {
      //     self.overlay = false;
      //     let studentPay = res.data.map((student) => {
      //       return {
      //         studentName: student.studentName,
      //         level: student.level,
      //         idStudent: student.idStudent,
      //         acceptedName: student.acceptedName,
      //         studyType: student.studyType,
      //         sectionName: student.sectionName,
      //         isPaid: self.studentPayment(student.payments) == "مسدد" ? true : false,
      //         paidPercentage: self.percentagePaidStudent(student.payments),
      //       };
      //     });
      //     console.log(res.data);
      //     let data = {
      //       paymentTypeId: self.paymentTypeId,
      //       students: self.typeOfPayId == 1 ? studentPay.filter((student) => student.isPaid == true) : self.typeOfPayId == 2 ? studentPay.filter((student) => student.isPaid == false) : studentPay,
      //     };
      //     this.$emit("clicked", data);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     self.overlay = false;
      //   });
      this.searchDialog = false;
    },
    percentagePaidStudentV2(payments, sectionInstallment) {
      let self = this;

      if (payments && sectionInstallment) {
        let allPaid = payments.filter(
          (pay) =>
            pay.paymentTypeId == 1 ||
            pay.paymentTypeId == 2 ||
            pay.paymentTypeId == 3 ||
            pay.paymentTypeId == 4 ||
            pay.paymentTypeId == 5 ||
            pay.paymentTypeId == 6 ||
            pay.paymentTypeId == 7 ||
            pay.paymentTypeId == 8 ||
            pay.paymentTypeId == 9
        );
        let paid = ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1) / sectionInstallment.amount) * 1 * 100;
        let alreadyPaid = allPaid.reduce((n, { amount }) => n + amount, 0) * 1;

        return {
          paid: Math.floor(paid),
          alreadyPaid: alreadyPaid,
        };
      } else {
        return {
          paid: 0,
          alreadyPaid: 0,
        };
      }
    },

    percentagePaidStudent(payments) {
      let self = this;
      let foundInstallment = self.allSectionInstallments.find((section) => section.sectionId == self.sectionId && section.level == self.level);
      if (payments && foundInstallment) {
        let allPaid = payments.filter(
          (pay) =>
            pay.paymentTypeId == 1 ||
            pay.paymentTypeId == 2 ||
            pay.paymentTypeId == 3 ||
            pay.paymentTypeId == 4 ||
            pay.paymentTypeId == 5 ||
            pay.paymentTypeId == 6 ||
            pay.paymentTypeId == 7 ||
            pay.paymentTypeId == 8 ||
            pay.paymentTypeId == 9
        );
        let paid = ((allPaid.reduce((n, { amount }) => n + amount, 0) * 1) / foundInstallment.amount) * 1 * 100;
        let alreadyPaid = allPaid.reduce((n, { amount }) => n + amount, 0) * 1;

        return {
          paid: Math.floor(paid),
          alreadyPaid: alreadyPaid,
        };
      } else {
        return {
          paid: 0,
          alreadyPaid: 0,
        };
      }
    },
    studentPayment(payments) {
      let self = this;
      if (payments) {
        if (self.paymentTypeId == 36) {
          let foundPaid = payments.filter((pay) => pay.paymentTypeId == 36);
          if (foundPaid.length > 0) {
            return "مسدد";
          } else {
            return "غير مسدد";
          }
        } else if (self.paymentTypeId == 24) {
          let foundPaid = payments.filter((pay) => pay.paymentTypeId >= 24);
          if (foundPaid.length > 0) {
            return "مسدد";
          } else {
            return "غير مسدد";
          }
        }
      } else {
        return "غير مسدد";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
