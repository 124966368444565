<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>صفحة المصروفات</h3>
          </v-col>
          <v-col cols="2" align="left">
            <AddExpensesRequest style="display: inline-block" @reloadTask="initialData" v-if="!overlay" />
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="expenses" :items-per-page="15" :search="search" item-key="idReceipt" class="table-content">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <p style="padding: 0 3px">
              {{
                item.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </p>
          </template>

          <template v-slot:[`item.isMark`]="{ item }">
            <v-btn icon v-if="item.isMark != 0" @click="changeIsMark(item)">
              <v-icon color="success">mdi-check-circle</v-icon>
            </v-btn>

            <v-btn icon v-else @click="changeIsMark(item)">
              <v-icon color="#9f9f9f">mdi-adjust</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.adminNotes`]="{ item }">
            {{ item.requestStatusId != 1 ? item.adminNotes : "الصرفية قيد المعاينة" }}
          </template>

          <template v-slot:[`item.requestStatusId`]="{ item }">
            <v-icon :color="requestStatus(item.requestStatusId).color">{{ requestStatus(item.requestStatusId).icon }}</v-icon>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <!-- <EditExpenses :expensesTypes="expensesTypes" :expense="item" style="display: inline-block" @reloadTask="initialData" /> -->

            <ExpensesImagesRequest :expenses="item" style="display: inline-block" @reloadTask="initialData()" />
            <!-- <DeleteDialog :dataToDelete="item" :url="`${$store.state.apiUrlFees}/expenses/${item.idExpenses}`" style="display: inline-block" @reloadTask="initialData()" /> -->
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <canvas id="barcode"></canvas>
  </div>
</template>

<script>
import AddExpensesRequest from "../components/AddExpensesRequest.vue";
import EditExpenses from "../components/EditExpenses.vue";
import DeleteDialog from "../components/DeleteDialog.vue";
import ExpensesImagesRequest from "../components/ExpensesImagesRequest.vue";

export default {
  components: {
    AddExpensesRequest,
    EditExpenses,
    DeleteDialog,
    ExpensesImagesRequest,
  },
  data: () => ({
    overlay: false,
    search: "",
    expenses: [],
    boxes: [],
    expensesTypes: [],
    headers: [
      { text: "التسلسل", value: "index", width: 100 },
      { text: "العنوان", value: "requestTitle" },
      { text: "وصف الصرفية", value: "requestDescription" },
      { text: "ملاحظات المسؤول", value: "adminNotes" },
      { text: "المبلغ", value: "amount" },
      { text: "بواسطة", value: "employeeName" },
      { text: "حالة الصرفية", value: "requestStatusId" },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/expensesRequests`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/boxes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/expensesTypes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((allExpenses, boxes, expensesTypes) => {
            console.log(allExpenses);
            self.expenses = allExpenses.data.reverse();
            self.boxes = boxes.data;
            self.expensesTypes = expensesTypes.data;
            self.overlay = false;
          })
        )
        .catch((e) => {
          console.log(e);
          self.overlay = false;
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },

    requestStatus(statusId) {
      if (statusId == 1) {
        return {
          icon: "mdi-timer-alert-outline",
          color: "warning",
        };
      } else if (statusId == 2) {
        return {
          icon: "mdi-check-circle",
          color: "success",
        };
      } else if (statusId == 3) {
        return {
          icon: "mdi-timer-lock",
          color: "info",
        };
      } else if (statusId == 4) {
        return {
          icon: "mdi-close",
          color: "error",
        };
      }
    },

    changeIsMark(item) {
      console.log(item);
      this.$http
        .put(
          `${this.$store.state.apiUrlFees}/expensesIsMark/${item.idExpenses}`,
          {
            isMark: item.isMark == 0 ? 1 : 0,
          },
          {
            headers: { Authorization: `Bearer ${this.$store.state.user}` },
          }
        )
        .then((res) => {
          this.show = false;
          this.$toasted.success("تم تعديل البيانات");
          this.initialData();
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
  },
};
</script>

<style lang="scss"></style>
