<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة قيمة للتخفيض</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <vuetify-money
            v-model="amount"
            v-bind:label="label"
            :rules="studentRequiredFormRules"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
          />

          <v-file-input multiple label="الملفات" outlined v-model="files" @change="getAllFiles"></v-file-input>

          <v-textarea v-model="notes" outlined label="الملاحظات" :rules="studentRequiredFormRules"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn class="mx-2" small text dark color="primary" @click="show = true"> <v-icon dark> mdi-plus </v-icon> اضافة قيمة تخفيض</v-btn>
  </div>
</template>

<script>
export default {
  props: {
    collegeNumber: String,
    yearStudyId: Number,
    studentLevel: Number,
  },
  data() {
    return {
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      show: false,
      notes: "",
      files: [],
      amount: "0",
      label: "المبلغ",
      placeholder: " ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "",
      studentImage: "",
      options: {
        locale: "pt-BR",
        prefix: "IQ",
        suffix: "",
        length: 11,
        precision: 0,
      },
    };
  },

  methods: {
    saveData() {
      let self = this;
      console.log(self.collegeNumber, self.studentImage);

      if (self.notes && self.amount && self.studentImage) {
        let data = {
          collegeNumber: self.collegeNumber,
          yearStudyId: self.yearStudyId,
          notes: self.notes,
          amount: self.amount,
          studentLevel: self.studentLevel,
          imagePath: self.studentImage,
          createdBy: self.$store.state.userData.idEmployee,
        };

        console.log(data);

        this.$http
          .post(`${this.$store.state.apiUrlFees}/addStudentDiscountAmount`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            this.show = false;
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          })
          .catch((err) => {
            this.$toasted.error(" حدث خطأ في البيانات");
          });
      } else {
        this.$toasted.error("لا يمكن ترك الحقل فارغ");
      }
    },
    getAllFiles() {
      let self = this;

      if (self.files.length > 0) {
        self.overlay = true;
        let formData = new FormData();
        formData.append("files", self.files[0]);

        console.log(self.files[0]);
        self.$http.post(`${self.$store.state.apiUrlFees}/uploadDiscountImage`, formData).then((res) => {
          self.overlay = false;
          self.studentImage = res.data.imagePath;
          console.log(res);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
