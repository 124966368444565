<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل معلومات الصرف</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-autocomplete
            v-model="expense.expensesTypeId"
            :items="expensesTypes"
            :rules="studentRequiredFormRules"
            item-text="typeName"
            item-value="idExpensesType"
            outlined
            label="التبويب"
          ></v-autocomplete>

          <v-text-field label="تاريخ الصرف" outlined type="date" v-model="validDate"></v-text-field>

          <v-textarea outlined name="input-7-4" label="Outlined textarea" v-model="expense.notes"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon @click="show = true" v-bind="attrs" v-on="on">
          <v-icon color="primary"> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <span>تعديل معلومات الصرف</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    expense: Object,
    expensesTypes: Array,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    validDate: "",
    levels: [
      { levelName: "المرحلة الاولى", level: 1 },
      { levelName: "المرحلة الثانية", level: 2 },
      { levelName: "المرحلة الثالثة", level: 3 },
      { levelName: "المرحلة الرابعة", level: 4 },
      { levelName: "المرحلة الخامسة", level: 5 },
    ],
    studyTypes: [
      { typeName: "صباحي", studyId: 1 },
      { typeName: "مسائي", studyId: 2 },
    ],

    amount: "0",
    label: "قيمة القسط",
    placeholder: " ",
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "IQ",
      suffix: "",
      length: 11,
      precision: 0,
    },
  }),
  created() {
    let currentDate = this.expense.expensesDateFormat.split("/");
    let validDate = `${currentDate[2]}-${currentDate[1]}-${currentDate[0]}`;
    this.validDate = validDate;
  },
  methods: {
    saveData() {
      let self = this;
      let expense = {
        expensesTypeId: self.expense.expensesTypeId,
        amount: self.expense.amount,
        notes: self.expense.notes,
        expensesDate: self.validDate,
        createdBy: self.expense.createdBy,
        boxId: self.expense.boxId,
        studyYearId: self.expense.studyYearId,
      };
      console.log(expense);
      this.$http
        .put(
          `${this.$store.state.apiUrlFees}/expenses/${this.expense.idExpenses}`,

          expense,
          {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }
        )
        .then((res) => {
          this.show = false;
          this.$toasted.success("تم اضافه البيانات");
          this.$emit("reloadTask");
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
