<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>صفحة اقساط الاقسام</h3>
          </v-col>
          <v-col cols="2" align="left">
            <AddSectionCost style="display: inline-block" :sectionInstallments="sectionInstallments" @reloadTask="initialData" v-if="!overlay && $store.state.userData.idEmployee != 614" />
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="sectionInstallments" :items-per-page="15" :search="search" item-key="idStudent" class="table-content">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            <p style="padding: 0 3px">{{ item.level | setLevel }}</p>
          </template>

          <template v-slot:[`item.studyType`]="{ item }">
            <p style="padding: 0 3px">{{ item.studyType | setStudyType }}</p>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <p style="padding: 0 3px">
              {{
                item.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </p>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <EditSectionInstallments
              v-if="$store.state.userData.idEmployee != 614"
              :installment="item"
              style="display: inline-block"
              :sectionInstallments="sectionInstallments"
              @reloadTask="initialData"
            />
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddSectionCost from "../components/AddSectionCost.vue";
import EditSectionInstallments from "../components/EditSectionInstallments.vue";
export default {
  components: {
    AddSectionCost,
    EditSectionInstallments,
  },
  data: () => ({
    search: "",
    overlay: false,
    selected: [],
    sectionInstallments: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "القسم", value: "sectionName" },
      { text: "المرحلة", value: "level" },
      { text: "نوع الدراسة", value: "studyType" },
      { text: "القسط", value: "amount" },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.overlay = true;
      self.$http
        .get(`${self.$store.state.apiUrlFees}/allSectionInstallments/75`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.sectionInstallments = res.data;
          self.overlay = false;
        })
        .catch((e) => {
          console.log(e);
          self.overlay = false;
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
    selectStudent(student) {
      console.log(student);
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      }
    },
    setStudyType: function (studyType) {
      if (studyType == 1) {
        return "صباحي";
      } else {
        return "مسائي";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
