<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل بيانات الطالب</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-text-field v-model="student.studentName" outlined label="اسم الطالب"></v-text-field>

          <v-autocomplete v-model="student.studyType" outlined color="primary" label="نوع الدراسة" :items="selectStudyType" item-text="text" item-value="value" item-color="primary"></v-autocomplete>

          <v-autocomplete
            v-model="student.sectionId"
            outlined
            color="primary"
            label="القسم"
            :items="$store.state.sections"
            item-text="sectionName"
            item-value="idSection"
            item-color="primary"
          ></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> تعديل </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="mx-2" color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon color="success"> mdi-pencil-circle </v-icon>
        </v-btn>
      </template>
      <span>تعديل بيانات الطالب</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    student: Object,
  },
  data() {
    return {
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      show: false,
      studentName: "",
      selectStudyType: [
        {
          text: "صباحي",
          value: "1",
        },
        {
          text: "مسائي",
          value: "2",
        },
      ],
    };
  },

  methods: {
    saveData() {
      let self = this;
      console.log(self.student);
      this.$http
        .put(
          `${this.$store.state.apiUrlRegistration}/student/${self.student.idStudent}`,
          {
            studentName: self.student.studentName,
            englishName: self.student.englishName,
            mail: self.student.mail,
            dob: self.student.dob,
            sectionId: self.student.sectionId,
            nationality: self.student.nationality,
            gender: self.student.gender,
            studyType: self.student.studyType,
            note: self.student.note,
            collegeNumber: self.student.collegeNumber,
            registerYearId: self.student.registerYearId,
            studentStatusId: self.student.studentStatusId,
            acceptedTypeId: self.student.acceptedTypeId,
          },
          {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }
        )
        .then((res) => {
          console.log(res.data);
          this.show = false;
          this.$toasted.success("تم تعديل البيانات");
          this.$emit("reloadTask");
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
